<template>
    <div>
        <div v-if="!showSelection && !showPaymentHistory">
            <div class="subscription-show">
                <md-button class="md-dense md-primary button-set" @click="$emit('close')"><span
                    class="light-md-button">Закрыть</span></md-button>
                <md-button class="md-dense md-primary button-set" @click="showPaymentHistory = true"><span
                    class="light-md-button">История платежей</span></md-button>
                <div class="grid-container-p">
                    <div class="grid-item"><span class="static-line">Ваша подписка</span></div>
                    <div class="grid-item">{{ subscription.name }}</div>

                    <div class="grid-item"><span class="static-line">Сведения</span></div>
                    <div class="grid-item">{{ subscription.description }}</div>

                    <div class="grid-item"><span class="static-line">Период действия</span></div>
                    <div class="grid-item">{{ periodInDays }}</div>

                    <div class="grid-item"><span class="static-line">Цена</span></div>
                    <div class="grid-item">{{ subscription.price }} ₽</div>

                    <div class="grid-item"><span class="static-line">Дата начала</span></div>
                    <div class="grid-item">{{ startDateFormatted }}</div>

                    <div class="grid-item"><span class="static-line">Дата конца</span></div>
                    <div class="grid-item">{{ expiresDateFormatted }}</div>
                </div>
                <div class="pay-but" v-if="subscription.name">
                    <md-button class="md-raised md-primary pay-but" @click="showSubscriptionSelection">Оформить
                        подписку</md-button>
                </div>
                <md-dialog :md-active.sync="showDialog">
                    <md-dialog-title>Ошибка</md-dialog-title>
                    <md-dialog-content>
                        {{ errorMessage }}
                    </md-dialog-content>
                    <md-dialog-actions>
                        <md-button class="md-primary" @click="showDialog = false">Закрыть</md-button>
                    </md-dialog-actions>
                </md-dialog>

            </div>

        </div>
        <!-- отображение выбора подписок -->
        <SubscriptionSelection v-if="showSelection" @back="showSelection = false" />
        <!-- отображение истории платежей -->
        <PaymentHistory v-if="showPaymentHistory" @close="showPaymentHistory = false" />
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import PaymentHistory from './PaymentHistory.vue';
import SubscriptionSelection from './SubscriptionSelection.vue';

export default {
    name: 'SubscriptionInfo',
    components: {
        PaymentHistory,
        SubscriptionSelection
    },
    data() {
        return {
            subscription: {},
            startDate: '',
            expiresDate: '',
            showDialog: false,
            errorMessage: '',
            showPaymentHistory: false,
            showSelection: false
        }
    },
    computed: {
        periodInDays() {
            const periodMatch = this.subscription.period.match(/\d+(?=\s+days)/);
            return periodMatch ? periodMatch[0] + ' дней' : '';
        },
        startDateFormatted() {
            return moment(this.startDate).format('DD.MM.YYYY');
        },
        expiresDateFormatted() {
            return moment(this.expiresDate).format('DD.MM.YYYY');
        }
    },
    methods: {
        showSubscriptionSelection() {
            this.showSelection = true;
        },
        // change() {
        //     axios.get('/subscription/payment/new_payment/?subscription_id=1')
        //         .then(response => {
        //             window.location.href = response.data.confirmation_url;
        //         })
        //         .catch((error) => {
        //             console.error(error);
        //             this.errorMessage = 'Не удалось произвести оплату';
        //             this.showDialog = true;
        //         });
        // },
        getSubscription() {
            axios.get('/api/subscription/subscription/current/')
                .then(response => {
                    this.subscription = response.data.subscription;
                    this.startDate = response.data.start_date;
                    this.expiresDate = response.data.expires;
                })
                .catch(error => {
                    if (error.response && error.response.status === 404) {
                        this.errorMessage = 'Нет активной подписки';
                        this.showDialog = true;
                        this.subscription = {
                            name: 'Нет активной подписки',
                            description: 'В данный момент у вас нет активной подписки.',
                            period: '0 дней',
                            price: '0'
                        };
                        this.startDate = moment().format('YYYY-MM-DD');
                        this.expiresDate = moment().format('YYYY-MM-DD');
                    } else {
                        this.errorMessage = 'Что-то пошло не так';
                        this.showDialog = true;
                    }
                });
        },
        handleError(errorMessage) {
            this.errorMessage = errorMessage;
            this.showDialog = true;
        }
    },
    created() {
        this.getSubscription();
    }
};
</script>

<style>
.subscription-show {
    max-width: 35vw;
}

.grid-container-p {
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
    row-gap: 2rem;
    padding-left: 10px;
    max-width: 45vw;
    padding-top: 4vh;
    padding-bottom: 4vh;
}

.static-line {
    color: #767676;
}

.static-line {
    color: #767676;
}

.pay-but {
    border-radius: 5px;
    text-transform: none;
}
</style>