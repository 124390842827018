<template>
  <div class="captcha-container md-layout md-gutter">
    <div v-if="!showPhoneVerification" class="md-layout md-align-center">
      <md-button class="md-icon-button" @click="goBack">
        <md-icon>arrow_back</md-icon>
      </md-button>
      <p class="md-title">Введите код с картинки:</p>

      <div class="captcha-image md-layout-item">
        <md-image :src="captchaImageUrl" alt="Captcha" />
      </div>

      <md-field class="md-layout-item">
        <md-input v-model="captchaInput" type="text" placeholder="Введите код"></md-input>
      </md-field>

      <md-button class="md-primary md-raised notUpper-md-button" @click="submitCaptcha">Подтвердить</md-button>
    </div>

    <PhoneVerification v-if="showPhoneVerification" @go-back="$emit('go-back')" />
  </div>
</template>

<script>
  import PhoneVerification from "./PhoneVerification.vue";
  export default {
    name: "Captcha",
    components: {
      PhoneVerification,
    },
    data() {
      return {
        captchaInput: "",
        captchaImageUrl: "https://view.drsoft.ru/media/owzguxxs67.png",
        showPhoneVerification: false,
      };
    },
    methods: {
      goBack() {
        this.$emit("go-back");
      },
      submitCaptcha() {
        console.log("Подтверждение капчи:", this.captchaInput);
        // Отправляем запрос на сервер с текстом капчи
        this.$axios
          .get(`/auth/phone/check/`, {
            params: {
              phone: "", // Здесь должен быть номер телефона пользователя
              code: this.captchaInput,
            },
          })
          .then((response) => {
            // Проверяем ответ сервера и переходим к следующему этапу, если капча верна
            if (response.data.success) {
              this.showPhoneVerification = true;
            } else {
              // Если капча неверна, выведите соответствующее сообщение об ошибке
              this.$message({
                message: "Введен неправильный код капчи",
                type: "error",
              });
            }
          })
          .catch((error) => {
            // Обработка ошибок запроса
            console.error("Ошибка при проверке капчи:", error);
          });
      },
    },
  };
</script>

<style scoped>
  .captcha-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .input-field {
    margin: 10px 0;
  }
</style>