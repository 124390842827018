<template>
  <div>
    <div v-if="selectedGroup === null">
      <div class="md-layout-v">
        <md-content v-for="(block, index) in blocks" :key="index"
          class="md-elevation-2 md-layout-item-bp organization-block" :style="getBlockBorderStyle(block)"
          @click="showTaskList(index)">
          <div class="md-list-item-text">
            <span class="md-title">{{ block.title }}</span>
            <span class="md-date">{{ block.date }}</span>
          </div>
          <div class="md-primary progress-container" v-if="block.progressPercentage !== null">
            <span class="md-subheading progress-percentage">{{ block.progressPercentage }}%</span>
          </div>
        </md-content>
      </div>
      <div class="loading-container" v-if="loadingData">
        <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
      </div>
    </div>
    <component v-else :is="selectedComponent" :group-id="selectedGroup" :title="selectedTitle"
      :progress-percentage="selectedProgressPercentage" @goBack="goBack"></component>
  </div>
</template>

<script>
  import axios from "axios";
  import TaskList from "@/components/Task/TaskList.vue";

  import {
    mapGetters
  } from 'vuex';

  export default {
    name: "BusinessProcessList",
    components: {
      TaskList
    },
    computed: {
      ...mapGetters('me', {}),
    },
    props: {
      selectedDepartment: {
        type: [String, Number],
        default: null
      },
      selectedDates: {
        type: Array,
        default: () => []
      },
      selectedEmployee: {
        type: [String, Number],
        default: null
      },
    },
    data() {
      return {
        blocks: [],
        selectedComponent: null,
        selectedGroup: null,
        selectedTitle: "",
        selectedProgressPercentage: null,
        loadingData: false,
        dateRange: [],
        isDataFetched: false
      };
    },
    created() {
      this.loadCachedData();
      this.fetchData();
    },
    beforeRouteLeave(to, from, next) {
      sessionStorage.setItem("tabPosition", this.tabPosition);
      next();
    },
    watch: {
      selectedDepartment(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.fetchData();
        }
      },
      selectedEmployee (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.fetchData();
        }
      },
      selectedDates(newValue, oldValue) {
        if (newValue !== oldValue) {
          console.log('Selected dates changed:', newValue);
          this.fetchData();
        }
      }
    },
    methods: {
      showTaskList(index) {
        const block = this.blocks[index];
        if (block.processStatus === 'unknown') {
          this.$notify({
            title: 'Внимание',
            message: 'В данном проекте отсутствуют задачи',
            type: 'warning'
          });
          return;
        }
        const groupId = block.id;
        const title = block.title;
        const progressPercentage = block.progressPercentage;
        this.$router.push({
          name: 'task-list',
          params: {
            groupId,
            title,
            progressPercentage
          }
        });
      },
      goBack() {
        this.selectedComponent = null;
        this.selectedGroup = null;
        this.selectedTitle = "";
        this.selectedProgressPercentage = null;
      },
      async loadCachedData() {
        const cachedData = sessionStorage.getItem("businessProcessListData");
        if (cachedData) {
          this.blocks = JSON.parse(cachedData);
        }
      },
      async fetchData() {
        console.log('Fetching data with departmentId:', this.selectedDepartment, 'and dates:', this.selectedDates);
        try {
          this.loadingData = true;
          let url = "/api/process/processes/?closed=0";
          this.isDataFetched = false;
          if (this.selectedDepartment) {
            url += `&department_id=${this.selectedDepartment}`;
          }
          if (this.selectedEmployee) {
            url += `&user_id=${this.selectedEmployee}`;
          }
          if (this.selectedDates && this.selectedDates.length === 2) {
            const date_start = this.formatDateISO(this.selectedDates[0]);
            const date_stop = this.formatDateISO(this.selectedDates[1]);
            url += `&date_start=${date_start}&date_stop=${date_stop}`;
          }
          // const params = {
          //   closed: 0
          // };
          // if (this.selectedDates && this.selectedDates.length === 2) {
          //   params.date_start = this.selectedDates[0].toISOString().split('T')[
          //   0]; // This will convert the date to YYYY-MM-DD format
          //   params.date_stop = this.selectedDates[1].toISOString().split('T')[0];
          // }
          // if (this.selectedDates && this.selectedDates.length === 2) {
          //   const date_start = this.selectedDates[0];
          //   const date_stop = this.selectedDates[1];
          //   url += `&date_start=${date_start}&date_stop=${date_stop}`;
          // }
          // if (this.selectedDates && this.selectedDates.length === 2) {
          //   const date_start = new Date(this.selectedDates[0].getFullYear(), this.selectedDates[0].getMonth(), this.selectedDates[
          //     0].getDate() + 1).toISOString().split('T')[0] + 'T00:00:00.000+03:00';
          //   const date_stop = new Date(this.selectedDates[1].getFullYear(), this.selectedDates[1].getMonth(), this.selectedDates[
          //     1].getDate() + 1).toISOString().split('T')[0] + 'T00:00:00.000+03:00';
          //   url += `&date_start=${date_start}&date_stop=${date_stop}`;
          // }
          console.log(`Fetching data with departmentId: ${this.selectedDepartment} and dates: ${this.selectedDates}`);

          const response = await axios.get(url);
          const groups = response.data.groups;
          const updatedBlocks = groups.map((group) => {
            const progressPercentage = group.info.completed_percentage;
            return {
              id: group.id,
              title: group.name,
              date: this.formatDate(group.date),
              progressPercentage,
              processStatus: group.info.process_status,
            };
          });
          this.blocks = updatedBlocks;
          sessionStorage.setItem("businessProcessListData", JSON.stringify(updatedBlocks));
          if (this.dateRange && this.dateRange.length === 2) {
            this.isDataFetched = true;
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          this.loadingData = false;
        }
      },
      formatDateISO(date) {
        const tzoffset = date.getTimezoneOffset() * 60000;
        const localISOTime = (new Date(date - tzoffset)).toISOString().slice(0, -1);
        return localISOTime;
      },
      formatDate(dateString) {
        const date = new Date(dateString);
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        };
        return date.toLocaleDateString("ru-RU", options);
      },
      handleDateChange(value) {
        this.dateRange = value;
        this.fetchData();
      },
      resetData() {
        this.dateRange = [];
        this.fetchData();
      },
      getBlockBorderStyle(block) {
        if (block.processStatus == 'overdue') {
          return {
            borderColor: '#F57575',
            borderWidth: '2px',
            borderStyle: 'solid'
          };
        } else if (block.processStatus == 'unknown') {
          return {
            borderColor: 'black',
            borderWidth: '2px',
            borderStyle: 'solid'
          };
        } else {
          return {
            borderColor: '#9BE18A',
            borderWidth: '2px',
            borderStyle: 'solid'
          };
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .md-layout-v {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    min-height: 150px;
    padding-left: 1vh;
  }

  .md-layout-item-bp {
    width: 300px;
    height: 150px;
    margin: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
    position: relative;
    border-radius: 10px;
    min-width: 300px !important;
    max-width: 310px;
    box-sizing: border-box;
    padding: 16px;
  }

  .organization-block {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
  }

  .md-list-item-text {
    overflow-wrap: break-word;
    word-break: break-word;
    display: -webkit-box;
    align-items: flex-start;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 100%;
    padding: 1vh;
    text-align: left;
  }

  .md-title {
    font-size: 1.2rem;
    display: block;
  }

  .md-date {
    font-size: 1rem;
    margin-top: 0.5rem;
    display: block;
  }

  .progress-container {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  .progress-percentage {
    font-size: 200%;
    color: #0077B6;
  }

  .grid-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin: 16px;
    justify-content: center;
  }

  .grid-item {
    width: 300px;
    height: 150px;
    margin: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
    position: relative;
    border-radius: 10px;
    min-width: 200px !important;
    max-width: 310px;
    box-sizing: border-box;
    padding: 16px;
  }

  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;

  }
</style>