<template>
    <div class="md-layout-v">
        <div v-for="(task, index) in tasks" :key="index" class="task-grid-container">
            <md-content :style="{
                'border-color': getRectangleColor(task.status),
            }" class="md-elevation-2 md-layout-item organization-block" @mouseenter="expandTask(index)"
                @mouseleave="collapseTask()">
                <div class="md-list-item-text">
                    <div class="first-line-task">
                        <span class="md-status">
                            {{ task.status }}
                        </span>
                        <i class="el-icon-link" @click.stop="redirectToBitrix(task.bitrix_url)"></i>
                    </div>
                    <span class="md-title">
                        {{ task.title }}
                    </span>
                </div>
                <div :class="{ expanded: expandedTaskIndex === index }" v-show="expandedTaskIndex === index">
                    <div class="grid-container" :class="{ expanded: expandedTaskIndex === index }">
                        <div class="grid-item">
                            <span class="md-caption"> Ответственный </span>
                        </div>
                        <div class="grid-item">
                            {{ task.responsible.name }}
                            <span class="md-caption">
                                {{ task.responsible.workPosition }}
                            </span>
                        </div>
                        <div class="grid-item">
                            <span class="md-caption"> Статус </span>
                        </div>
                        <div class="grid-item">
                            {{ task.status }}
                        </div>
                        <div class="grid-item">
                            <span class="md-caption"> Дата начала </span>
                        </div>
                        <div class="grid-item">
                            {{ task.date_start ? formatDate(task.date_start) : "Не указано" }}
                        </div>
                        <div class="grid-item">
                            <span class="md-caption"> Планируемая дата завершения </span>
                        </div>
                        <div class="grid-item">
                            {{ task.deadline ? formatDate(task.deadline) : "Не указано" }}
                        </div>
                        <div class="grid-item">
                            <span class="md-caption"> Фактическая дата завершения </span>
                        </div>
                        <div class="grid-item">
                            {{ task.closed_date ? formatDate(task.closed_date) : "Не указано" }}
                        </div>
                    </div>
                    <!-- <span class="demonstration">Важность задачи</span> -->
                    <!-- <el-slider class="custom-gradient-slider" v-model="task.weight" :step="1" show-stops :min="1" :max="10" @change="updateWeight(task)">
                    </el-slider> -->
                </div>
                <!-- <div @click.stop="toggleTaskDetails(index)" class="expand-indicator"></div> -->
                <div @click.stop="toggleTaskDetails(index)">
                    <div class="task-toggle-area">
                        <div class="expand-indicator">
                        </div>
                    </div>
                </div>
            </md-content>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    props: {
        task: {
            type: Object,
            required: true
        },
        initialData: {
            type: Object,
            required: true
        },
        initialSubTaskIds: {
            type: Array,
            required: true
        }
    },
    // created() {
    //     this.fetchDataForInitialData();
    // },
    data() {
        return {
            tasks: [],
            expandedTaskIndex: null,
            taskDetailsData: this.initialData
        };
    },
    watch: {
        task: [
            {
                handler() {
                    // this.fetchDataForInitialData();
                    this.fetchData();
                }
            },
            {
                deep: true,
                handler() {
                    this.fetchData();
                }
            }
        ]
    },
    mounted() {
        if (this.task && this.task.id) {
            this.fetchData();
        }
        if (this.initialSubTaskIds.length > 0) {
            this.fetchInitialSubTasks();
        }
    },
    methods: {
        // async fetchDataForInitialData() {
        //     if (this.initialData && Array.isArray(this.initialData)) {
        //         const promises = this.initialData.map(async (item) => {
        //             if (item.id) {
        //                 try {

        //                     const response = await axios.get(`/api/works/works/${item.id}/`);
        //                     const matchingTaskIndex = this.tasks.findIndex((task) => task.id === item.id);

        //                     if (matchingTaskIndex !== -1) {
        //                         this.$set(this.tasks, matchingTaskIndex, response.data);
        //                     }

        //                 } catch (error) {
        //                     // console.error("Error fetching data for ID:", item.id, error);
        //                 }
        //             }
        //         });

        //         await Promise.all(promises);
        //     }
        // },
        async fetchInitialSubTasks() {
            const promises = this.initialSubTaskIds.map(async id => {
                try {
                    const response = await axios.get(`/api/works/works/${id}/`);
                    // Здесь вы можно решить, как именно добавлять эти данные в Tasks
                    this.tasks.push(response.data);
                    // console.log("Fetched tasks:", this.Tasks);
                } catch (error) {
                    // console.error("Error fetching data for ID:", id, error);
                }
            });

            await Promise.all(promises);
        },
        async fetchData() {
            if (this.task.id) {
                try {
                    const response = await axios.get(`/api/works/works/get_children/`, {
                        params: { id: this.task.id }
                    });
                    // console.log('Response data:', response.data);
                    this.tasks = response.data;
                    // console.log('Filtered tasks after assignment:', this.Tasks);  новая строка
                } catch (error) {
                    //  console.error("Error fetching data for ID:", this.task.id, error);
                }
            }
        },
        toggleTaskDetails(index) {
            if (this.expandedTaskIndex === index) {
                this.expandedTaskIndex = null;
            } else {
                this.expandedTaskIndex = index;
            }
            this.$nextTick(() => this.updateLayoutItemHeight());
        },
        // expandTask(index) {
        //     this.expandedTaskIndex = index;
        //     this.$nextTick(() => this.updateLayoutItemHeight());
        // },
        // collapseTask() {
        //     this.expandedTaskIndex = null;
        //     this.$nextTick(() => this.updateLayoutItemHeight());
        // },
        updateLayoutItemHeight() {
            const layoutItems = this.$el.querySelectorAll('.md-layout-item');
            layoutItems.forEach((item, idx) => {
                if (this.expandedTaskIndex === idx) {
                    item.classList.add('expanded');
                } else {
                    item.classList.remove('expanded');
                }
            });
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
            };
            return date.toLocaleDateString("ru-RU", options);
        },
        getRectangleColor(status) {
            let borderColor = "#000"; // по умолчанию
            switch (status) {
                case "Новая":
                    borderColor = "#5A9"; // светло зелёный
                    break;
                case "В ожидании":
                    borderColor = "#FFD700"; // жёлтый
                    break;
                case "Выполняется":
                    borderColor = "#46D2C3"; // синезелёный
                    break;
                case "Ожидает подтверждения":
                    borderColor = "#FFA500"; // ораньжевый
                    break;
                case "Завершено":
                    borderColor = "#008000"; //зелёный
                    break;
                case "Отложено":
                    borderColor = "#696969"; // серый
                    break;
                case "Отклонено":
                    borderColor = "#B22222"; // кирпич
                    break;
                case "Просрочено":
                    borderColor = "#FF0000"; // красный
                    break;
                case "Завершено с просрочкой":
                    borderColor = "#8B0000"; // тёмнокрасный
                    break;
                default:
                    break;
            }
            return borderColor;
        },
        redirectToBitrix(url) {
            window.open(url, '_blank');
        },
        async updateWeight(task) {
            try {
                // отправка POST-запроса с данными
                await axios.post('/api/works/works/set_weights/', {
                    weight: task.weight,
                    task_id: parseInt(task.id)
                });
            } catch (error) {
                // console.error("An error occurred while sending the request", error);
            }
        },
    }
}
</script>

<style scoped>
.back-button {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.md-list-item-text {
    overflow-wrap: break-word;
    word-break: break-word;
    display: -webkit-box;
    align-items: flex-start;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 100%;
    padding: 1vh;
    text-align: left;
}

.task-grid-container {
    display: grid;
    grid-template-columns: 1fr;
    /* Один столбец для каждого блока */
    grid-auto-rows: min-content;
    /* Автоматическая высота строки */
    margin-bottom: 1rem;
    /* Отступ между блоками задач */
}

.md-layout-v {
    display: grid;
    grid-template-columns: repeat(var(--columns-count), 1fr);
    grid-auto-rows: min-content;
    padding-left: 2vh;
}

.material-icons {
    font-size: 32px;

    margin-right: 30px;
}

.md-title {
    display: block;
    white-space: nowrap;
    /* Убираем переносы строк */
    overflow: hidden;
    /* Скрываем текст, выходящий за пределы блока */
    text-overflow: ellipsis;
    /* Добавляем многоточие в конце */
    max-width: 250px;
    /* Максимальная ширина текста, можете настроить под свои нужды */

}

/* Для переноса текста на новую строку, если он не умещается */
.md-title {
    white-space: normal;
    /* Включаем перенос текста на новые строки */
    line-height: 1.2;
    /* Настройка высоты строки для улучшения читаемости */
    font-size: 1.1rem;
}

.md-status {
    font-size: 1rem;
    margin-top: 0.5rem;
    display: block;
    color: #c4c4c4;
}

.md-layout-item {
    width: 300px;
    min-height: 150px;
    max-height: 150px;
    /* Set maximum height for closed state */
    transition: max-height 0.5s ease-in-out;
    margin: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
    position: relative;
    border-radius: 10px;
    min-width: 300px !important;
    max-width: 310px;
    box-sizing: border-box;
    padding: 16px;
    border: 2px solid #000;
}

.md-layout-item.expanded {
    max-height: none;
    /* or a very large value if 'none' doesn't work */
    transition: max-height 0.5s ease-in-out;
}


.grid-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
    padding: 1vh;
    height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    visibility: hidden;
    text-align: initial;
}

.grid-container.expanded {
    height: auto;
    visibility: visible;
}

.grid-item {}


.progress-percentage {
    font-size: 200%;
    color: #0077b6;
    margin-left: 1rem;
}

.task-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 120%;
}


.organization-block {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    position: relative;
}

.md-elevation-2 {
    box-sizing: border-box;
}

.header {
    display: flex;
    justify-content: space-between;

    align-items: center;
}

.chart-button {
    margin-right: 1rem;
    margin-left: auto;
    text-transform: none;
    border-radius: 5px;
}

.loading-spinner {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.expand-indicator {
    width: 65px;
    /* или другой размер, который вам подходит */
    height: 4px;
    background-color: #c3c3c3;
    /* или другой цвет */
    border-radius: 10px;
    /* делает элемент круглым */
    position: initial;
    /* позиционируем индикатор относительно родительского блока */
    bottom: 10px;
    /* отступ снизу */
    right: 10px;
    /* отступ справа */
    opacity: 0;
    /* transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; */
    margin: 0 auto;
}


.md-layout-item.organization-block:hover .expand-indicator {
    opacity: 1;
    /* Fully visible when parent block is hovered */
}

.fraction-display {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px;
    font-size: 0.9em;
    text-align: right;
}

.task-toggle-area {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
}

.first-line-task {
    display: flex;
    flex-direction: initial;
    justify-content: space-around;
}

.pag-but {
    border-radius: 5px;
    text-transform: none;
}

.el-icon-link {
    display: flex;
    justify-content: end;
    align-items: end;
}
</style>