<template>
  <ProcessTabs @selectedGroup="onSelectedGroup" />
</template>

<script>
  import ProcessTabs from '@/components/buildings/ProcessTabs.vue';

  export default {
    name: 'BusinessProcessPage',
    components: {
      ProcessTabs,
    },

    data() {
      return {};
    },

    mounted() {},

    methods: {
      onSelectedGroup(data) {
        const {
          groupId,
          title,
          progressPercentage
        } = data;
        this.$router.push({
          name: "taskList",
          params: {
            groupId,
            title,
            progressPercentage
          }
        });
      },
    },
  };
</script>