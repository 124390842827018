<template>
    <div>
        <div v-if="!showSubTaskList">
            <div class="header">
                <div @click="$router.go(-1)" class="back-button">
                    <i class="material-icons" style="color: #0077b6"> keyboard_backspace </i>
                    <span class="md-headline">
                        {{ processName }}
                    </span>
                    <span class="md-subheading progress-percentage">
                        {{ completedPercentage }}%
                    </span>
                </div>
                <md-button :progresspercentage="progressPercentage" :title="someTitle" @click="showChart(groupId)"
                    class="md-raised md-primary chart-button notUpper-md-button">
                    Построить график
                </md-button>
            </div>
            <md-progress-spinner class="loading-spinner" md-mode="indeterminate" v-if="tasks.length === 0">
            </md-progress-spinner>
            <status-filter v-if="isLoaded" :process-id="processId" @status-change="handleStatusChange" />
            <div class="md-layout-v">
                <div v-for="(task, index) in tasks" :key="index" class="task-grid-container" @click="showSubTask(task)">
                    <md-content :style="{
            'border-color': getRectangleColor(task.status),
        }" class="md-elevation-2 md-layout-item organization-block">
                        <!-- @mouseenter="expandTask(index)" @mouseleave="collapseTask()" -->
                        <div class="md-list-item-text">
                            <div class="first-line-task">
                                <span class="md-status">
                                    {{ task.status }}
                                </span>
                                <i class="el-icon-link" @click.stop="redirectToBitrix(task.bitrix_url)"></i>
                            </div>
                            <span class="md-title" :title="task.title">
                                {{ task.title }}
                            </span>
                        </div>
                        <div :class="{ expanded: expandedTaskIndex === index }" v-if="expandedTaskIndex === index">
                            <div class="grid-container" :class="{ expanded: expandedTaskIndex === index }">
                                <div class="grid-item">
                                    <span class="md-caption"> Ответственный </span>
                                </div>
                                <div class="grid-item">
                                    {{ task.responsible.name }}
                                    <span class="md-caption">
                                        {{ task.responsible.workPosition }}
                                    </span>
                                </div>
                                <div class="grid-item">
                                    <span class="md-caption"> Статус </span>
                                </div>
                                <div class="grid-item">
                                    {{ task.status }}
                                </div>
                                <div class="grid-item">
                                    <span class="md-caption"> Дата начала </span>
                                </div>
                                <div class="grid-item">
                                    {{ task.date_start ? formatDate(task.date_start) : "Не указано" }}
                                </div>
                                <div class="grid-item">
                                    <span class="md-caption"> Планируемая дата завершения </span>
                                </div>
                                <div class="grid-item">
                                    {{ task.deadline ? formatDate(task.deadline) : "Не указано" }}
                                </div>
                                <div class="grid-item">
                                    <span class="md-caption"> Фактическая дата завершения </span>
                                </div>
                                <div class="grid-item">
                                    {{ task.closed_date ? formatDate(task.closed_date) : "Не указано" }}
                                </div>
                            </div>
                            <div @click.stop>
                                <span class="demonstration">Важность задачи</span>
                                <el-slider class="custom-gradient-slider" v-model="task.weight" :step="1" show-stops
                                    :min="1" :max="10" @change="updateWeight(task)">
                                </el-slider>
                            </div>
                        </div>
                        <div @click.stop="toggleTaskDetails(index)">
                            <div class="task-toggle-area">
                                <div class="expand-indicator">
                                </div>
                                <span class="fraction-display">{{ calculateFraction(task) }}</span>
                            </div>
                        </div>
                    </md-content>
                </div>
            </div>
            <div class="pagination" v-if="count > 50">
                <md-button class="md-dense md-raised md-primary pag-but" @click="fetchTasks(prevPageUrl)"
                    :disabled="!prevPageUrl || currentPage === 1"><span
                        style="font-family: 'Gilroy-Light', sans-serif !important;">Назад</span></md-button>
                <span>{{ currentPage }} из {{ totalPages }}</span>
                <md-button class="md-dense md-raised md-primary pag-but" @click="fetchTasks(nextPageUrl)"
                    :disabled="!nextPageUrl || currentPage === totalPages"><span
                        style="font-family: 'Gilroy-Light', sans-serif !important;">Следующая</span></md-button>
            </div>
            <component :group-id="groupId" :is="'chart'" v-if="chartVisible">
            </component>
        </div>
        <div v-if="showSubTaskList">
            <sub-task-list :task-id="selectedTaskId" v-if="showSubTaskList" @close="showSubTaskListExample()"
                :title="selectedTaskTitle"></sub-task-list>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Chart from "@/components/graph/Chart.vue";
import SubTaskList from '@/components/Task/SubTaskList.vue';
import StatusFilter from './StatusFilter.vue';

export default {
    components: {
        Chart,
        SubTaskList,
        'status-filter': StatusFilter,
    },
    name: "TaskList",
    props: {
        groupId: {
            type: [String, Number],
            required: true,
        },
        completedPercentage: {
            // новый проп для процентов выполнения
            type: Number,
            default: 0,
        },
        processName: {
            // новый проп для имени процесса
            type: String,
            default: "",
        },
    },

    data() {
        return {
            tasks: [],
            chartVisible: false,
            tooltipVisible: [],
            expandedTaskIndex: null,
            expandedTasks: {},
            count: 0,
            nextPageUrl: null,
            prevPageUrl: null,
            hoveredTaskIndex: null,
            currentPage: 1,  // добавляем текущую страницу
            totalPages: 0,  // добавляем общее количество страниц
            showSubTaskList: false,
            selectedTaskId: null,
            processId: [],
            isLoaded: false,
            totalWeight: 0, // Инициализация общего веса
            weightUpdated: false,
            currentStatusFilter: [], // Инициализация переменной для хранения текущего фильтра статуса
        };
    },
    mounted() {
        document.documentElement.style.setProperty(
            "--columns-count",
            this.columnsCount.toString()
        );
        this.processId = this.$route.params.processId;
    },
    created() {
        this.fetchTasks();
        this.tooltipVisible = new Array(this.tasks.length).fill(false);
    },
    computed: {
        columnsCount() {
            return Math.floor(window.innerWidth / 348);
        },
        uniqueStatuses() {
            // Используем Set для удаления дубликатов и spread оператор для конвертации обратно в массив
            return [...new Set(this.tasks.map(task => task.status))];
        },
        selectedTaskTitle() {
            if (this.selectedTaskId) {
                const task = this.tasks.find(task => task.id === this.selectedTaskId);
                return task ? task.title : '';
            }
            return '';
        }
    },
    watch: {
        weightUpdated(newValue) {
            if (newValue) {
                this.fetchTasks().then(() => {
                    this.applyCurrentStatusFilter(); // Повторно применяем фильтр после обновления
                    this.weightUpdated = false; // сбрасываем флаг после обновления
                });
            }
        },
    },
    methods: {
        showSubTaskListExample(){
            this.showSubTaskList = false;
            this.expandedTaskIndex = null;
        },
        redirectToBitrix(url) {
            window.open(url, '_blank');
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
            };
            return date.toLocaleDateString("ru-RU", options);
        },
        async fetchTasks(url = `/api/process/processes/${this.groupId}/`) {
            try {
                console.log("Fetching tasks from URL:", url);  // Добавлено для отладки
                // Проверяем наличие данных в кэше
                const cachedTasks = localStorage.getItem(`tasks-${this.groupId}`);
                if (cachedTasks) {
                    this.tasks = JSON.parse(cachedTasks).tasks; // обновляем формат кэшированных данных
                    this.tooltipVisible = new Array(this.tasks.length).fill(false);
                }
                const response = await axios.get(url);
                const data = response.data;
                this.tasks = data.results;
                this.nextPageUrl = data.next;
                this.prevPageUrl = data.previous;
                this.processId = response.data.result.process.id;
                this.tasks = response.data.result.tasks;
                this.processName = response.data.result.process.name;
                this.completedPercentage = response.data.result.completed_percentage;
                this.tooltipVisible = new Array(this.tasks.length).fill(false);
                this.count = response.data.count;
                this.nextUrl = response.data.next;
                this.totalWeight = response.data.result.total_weight; // Обновление общего веса
                this.prevUrl = response.data.previous === 'None' ? null : response.data.previous;
                this.totalPages = Math.ceil(data.count / 50);  // каждая страница содержит 50 элементов
                this.currentPage = url ? this.getPageNumberFromUrl(url) : 1;  // обновляем текущую страницу
                console.log("Updated currentPage to:", this.currentPage);  // Добавлено для отладки
                // Сохраняем новые данные в кэше
                localStorage.setItem(
                    `tasks-${this.groupId}`,
                    JSON.stringify(response.data.result)
                );
                this.isLoaded = true;
            } catch (error) {
                console.error("Error fetching tasks:", error);
            }
        },
        updateTotalWeight() {
            // Вычисляем новый общий вес
            this.totalWeight = this.tasks.reduce((total, task) => total + task.weight, 0);
        },
        calculateFraction(task) {
            return `${task.weight_numerator}%/${task.weight_denominator}%`;
        },
        handleStatusChange(selectedStatusIds) {
            this.currentStatusFilter = selectedStatusIds; // Сохраняем текущий фильтр статуса
            const queryParams = selectedStatusIds.map(id => `status=${id}`).join('&');
            const url = `/api/process/processes/${this.processId}/?${queryParams}`;
            this.fetchTasks(url);
        },
        getPageNumberFromUrl(url) {
            // Пытаемся извлечь параметры limit и offset из URL
            const limitMatch = url.match(/limit=(\d+)/);
            const offsetMatch = url.match(/offset=(\d+)/);

            // Если оба параметра присутствуют, вычисляем номер страницы
            if (limitMatch && offsetMatch) {
                const limit = Number(limitMatch[1]);
                const offset = Number(offsetMatch[1]);
                return offset / limit + 1;
            }
            // Если параметры отсутствуют, вероятно, это первая страница
            return 1;
        },
        showSubTask(task) {
            if (task.has_children) {
                this.selectTask(task.id);  // Метод, который вы можете использовать для отображения подзадач
            } else {
                this.$notify({
                    title: "Внимание",
                    message: "У данной задачи отсутствуют подзадачи",
                    type: "warning",
                });
            }
        },
        selectTask(taskId) {
            this.selectedTaskId = taskId;
            this.showSubTaskList = true;
        },
        async showChart(groupId) {
            try {
                await axios.get(
                    `/api/graph/graph/process_performance/?process_id=${groupId}`
                );
                this.$router.push({
                    name: "chart",
                    params: {
                        groupId,
                    },
                });
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    this.$notify({
                        title: "Внимание",
                        message: "У одного из заданий нет дедлайна",
                        type: "warning",
                    });
                } else {
                    // Обработка других ошибок
                    console.error(error);
                }
            }
        },
        async updateWeight(task) {
            try {
                // отправка POST-запроса с данными
                await axios.post('/api/works/works/set_weights/', {
                    weight: task.weight,
                    task_id: parseInt(task.id)
                });
                this.weightUpdated = true; // устанавливаем флаг обновления веса
                this.applyCurrentStatusFilter(); // Повторно применяем фильтр после обновления
            } catch (error) {
                console.error("An error occurred while sending the request", error);
            }
        },
        applyCurrentStatusFilter() {
            // Метод для повторного применения текущего фильтра статуса
            if (this.currentStatusFilter.length > 0) {
                this.handleStatusChange(this.currentStatusFilter);
            }
        },
        getRectangleColor(status) {
            let borderColor = "#000"; // по умолчанию
            switch (status) {
                case "Новая":
                    borderColor = "#5A9"; // светло зелёный
                    break;
                case "В ожидании":
                    borderColor = "#FFD700"; // жёлтый
                    break;
                case "Выполняется":
                    borderColor = "#46D2C3"; // синезелёный
                    break;
                case "Ожидает подтверждения":
                    borderColor = "#FFA500"; // ораньжевый
                    break;
                case "Завершено":
                    borderColor = "#008000"; //зелёный
                    break;
                case "Отложено":
                    borderColor = "#696969"; // серый
                    break;
                case "Отклонено":
                    borderColor = "#B22222"; // кирпич
                    break;
                case "Просрочено":
                    borderColor = "#FF0000"; // красный
                    break;
                case "Завершено с просрочкой":
                    borderColor = "#8B0000"; // тёмнокрасный
                    break;
                default:
                    break;
            }
            return borderColor;
        },
        toggleTaskDetails(index) {
            if (this.expandedTaskIndex === index) {
                this.expandedTaskIndex = null;
            } else {
                this.expandedTaskIndex = index;
            }
            this.$nextTick(() => this.updateLayoutItemHeight());
        },
        // expandTask(index) {
        //     this.expandedTaskIndex = index;
        //     this.$nextTick(() => this.updateLayoutItemHeight());
        // },
        // collapseTask() {
        //     this.expandedTaskIndex = null;
        //     this.$nextTick(() => this.updateLayoutItemHeight());
        // },
        updateLayoutItemHeight() {
            const layoutItems = this.$el.querySelectorAll('.md-layout-item');
            layoutItems.forEach((item, idx) => {
                if (this.expandedTaskIndex === idx) {
                    item.classList.add('expanded');
                } else {
                    item.classList.remove('expanded');
                }
            });
        },
        toggleExpanded() {
            this.isExpanded = !this.isExpanded;
        },
        showTooltip(index) {
            this.$set(this.tooltipVisible, index, true);
        },
        hideTooltip(index) {
            this.$set(this.tooltipVisible, index, false);
        },
    },
};
</script>

<style scoped>
.back-button {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.md-list-item-text {
    overflow-wrap: break-word;
    word-break: break-word;
    display: -webkit-box;
    align-items: flex-start;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 100%;
    padding: 1vh;
    text-align: left;
}

.md-layout-v {
    display: grid;
    grid-template-columns: repeat(var(--columns-count), 1fr);
    grid-auto-rows: min-content;
    padding-left: 3vh;
}

.task-grid-container {
    display: grid;
    grid-template-columns: 1fr;
    /* Один столбец для каждого блока */
    grid-auto-rows: min-content;
    /* Автоматическая высота строки */
    margin-bottom: 1rem;
    /* Отступ между блоками задач */
}

.material-icons {
    font-size: 32px;

    margin-right: 30px;
}

.md-title {
    display: -webkit-box;

    -webkit-line-clamp: 4;
    /* Ограничить количество строк до 4 */
    /* white-space: nowrap; */
    white-space: normal;
    /* Убираем переносы строк */
    overflow: hidden;
    /* Скрываем текст, выходящий за пределы блока */
    text-overflow: clip;
    /* Добавляем многоточие в конце */
    max-width: 250px;
    /* Максимальная ширина текста, можете настроить под свои нужды */

}

/* Для переноса текста на новую строку, если он не умещается */
.md-title {
    white-space: normal;
    /* Включаем перенос текста на новые строки */
    line-height: 1.25;
    /* Настройка высоты строки для улучшения читаемости */
    font-size: 1.1rem;
}


.md-status {
    font-size: 1rem;
    margin-top: 0.5rem;
    display: block;
    color: #c4c4c4;
}

.md-layout-item {
    width: 300px;
    min-height: 150px;
    max-height: 150px;
    /* Set maximum height for closed state */
    transition: max-height 0.5s ease-in-out;
    margin: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
    position: relative;
    border-radius: 10px;
    min-width: 300px !important;
    max-width: 310px;
    box-sizing: border-box;
    padding: 16px;
    border: 2px solid #000;
    /* z-index: 1; */
}

.md-layout-item.expanded {
    /* position: absolute;
    z-index: 100; */
    /* если хотим чтоб блок наезжал на нижний */
    max-height: none !important;
    /* or a very large value if 'none' doesn't work */
    transition: max-height 0.5s ease-in-out;
}


.grid-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
    padding: 1vh;
    height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    visibility: hidden;
    text-align: initial;
}

.grid-container.expanded {
    height: auto;
    visibility: visible;
}

.grid-item {}


.progress-percentage {
    font-size: 200%;
    color: #0077b6;
    margin-left: 1rem;
}

.task-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 120%;
}


.organization-block {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    position: relative;
}

.md-elevation-2 {
    box-sizing: border-box;
}

.header {
    display: flex;
    justify-content: space-between;

    align-items: center;
}

.chart-button {
    margin-right: 1rem;
    margin-left: auto;
    text-transform: none;
    border-radius: 5px;
}

.loading-spinner {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.expand-indicator {
    width: 65px;
    /* или другой размер, который вам подходит */
    height: 4px;
    background-color: #c3c3c3;
    /* или другой цвет */
    border-radius: 10px;
    /* делает элемент круглым */
    position: initial;
    /* позиционируем индикатор относительно родительского блока */
    bottom: 10px;
    /* отступ снизу */
    right: 10px;
    /* отступ справа */
    opacity: 0;
    /* transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; */
    margin: 0 auto;
}


.md-layout-item.organization-block:hover .expand-indicator {
    opacity: 1;
    /* Fully visible when parent block is hovered */
}

.fraction-display {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px;
    font-size: 0.9em;
    text-align: right;
}

.task-toggle-area {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
}

.first-line-task {
    display: flex;
    flex-direction: initial;
    justify-content: space-around;
}

.pag-but {
    border-radius: 5px;
    text-transform: none;
}

.el-icon-link {
    display: flex;
    justify-content: end;
    align-items: end;
}
</style>
