<template>
  <div class="graph-chart-conteiner">
    <div class="header" style="margin-bottom: 1vh;">
      <div class="back-button" @click="$router.go(-1)">
        <i class="material-icons" style="color: #0077B6">keyboard_backspace</i>
        <span class="md-headline">{{ processName }}</span>
        <!--  <span class="md-subheading progress-percentage>
          {{ ProgressPercentage }}%
        </span>-->
      </div>
    </div>
    <!-- md-select для выбора типа графика -->
    <div class="select-and-help-container">
      <div class="select-filter">
        <md-field class="md-field-filtr">
          <label for="selectedGraphType">Выбор графика</label>
          <md-select v-model="selectedGraphType" name="Выбор графика">
            <md-option value="linear">Линейный график</md-option>
            <md-option value="bar">Столбчатая диаграмма</md-option>
          </md-select>
        </md-field>
      </div>
      <div @click="showDialog = true">
        <md-icon class="help-icon">help</md-icon>
      </div>
    </div>
    <div class="graph-container">
      <!-- Условная отрисовка компонента графика -->
      <component :is="graphComponent" :groupId="groupId"></component>
    </div>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Информация</md-dialog-title>
      <md-dialog-content>
        <p>Формула для оценки времени выполнения задачи в методе PERT:</p>
        <div style="text-align: center; font-size: 16px;">
          <p><strong>Ожидаемое время (TE) =</strong></p>
          <div style="display: inline-block;">
            <div style="border-bottom: 1px solid #000; padding: 0 5px;">
              O + 4M + P
            </div>
            <div style="padding: 0 5px;">
              6
            </div>
          </div>
        </div>
        <p>Где:</p>
        <ul>
          <li><strong>O</strong> - это оптимистичное время (когда всё идеально и быстро идет),</li>
          <li><strong>M</strong> - это ожидаемое время (когда всё идет, как обычно),</li>
          <li><strong>P</strong> - это пессимистичное время (когда всё идет медленно или возникают проблемы).</li>
        </ul>
        <p>Эта формула помогает получить среднюю оценку времени, учитывая разные сценарии.</p>
        <p>В нашем случае:</p>
        <ul>
          <li>Ожидаемое время - это то время, которое было установлено в Битрикс24 от начала задачи до дедлайна.</li>
          <li>Оптимистичное время <strong>O</strong> = Ожидаемое время <strong>M</strong> * <strong>K1</strong></li>
          <li>Пессимистичное время <strong>P</strong> = Ожидаемое время <strong>M</strong> / <strong>K2</strong></li>
        </ul>
        <p>Коэффициенты <strong>K1</strong> и <strong>K2</strong> вычисляются в соответствии с тем, как были выполнены
          задачи в прошлом. В начале работы <strong>K1</strong> и <strong>K2</strong> равны 0,70 и 0,75 соответственно.
        </p>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog = false">ОК</md-button>
      </md-dialog-actions>
    </md-dialog>

  </div>
</template>

<script>
import axios from "axios";
import GraphComponentLinear from "./GraphComponentLinear.vue";
import GraphComponentBar from "./GraphComponentBar.vue";

export default {
  components: {
    GraphComponentBar,
    GraphComponentLinear,
  },
  props: {
    groupId: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    progressPercentage: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      graphData: null,
      processName: '',
      selectedGraphType: 'linear', // По умолчанию выбран линейный график
      showDialog: false, // переменная для управления видимостью диалога
    };
  },
  computed: {
    graphComponent() {
      // Возвращает имя компонента на основе выбранного типа графика
      return this.selectedGraphType === 'linear' ? 'GraphComponentLinear' : 'GraphComponentBar';
    },
  },
  async created() {
    await this.fetchGraphData();
    this.$refs.graphComponent.drawGraph();
  },
  methods: {
    async fetchGraphData() {
      try {
        const response = await axios.get(
          `/api/graph/graph/process_performance/?process_id=${this.groupId}`
        );
        this.graphData = response.data;
        this.processName = this.graphData.process.name;
      } catch (error) {
        console.error("Failed to fetch graph data:", error);
      }
    },
  },
};
</script>

<style scoped>
.graph-chart-conteiner {
  overflow: hidden;
}

.back-button {
  display: flex;
  cursor: pointer;
  align-items: center;
  /* Отступ снизу */
}

.graph-container {
  display: block;
  width: 87vw;
  margin: auto;
  text-align: center;
}

.material-icons {
  font-size: 32px;
  /* Увеличение размера стрелки */
  margin-right: 30px;
}

.header {
  display: flex;
  justify-content: space-between;
  /* Добавлено для размещения кнопки справа */
  align-items: center;
}

.progress-percentage {
  font-size: 200%;
  color: #0077B6;
  margin-left: 1rem;
  /* Расстояние между title и progressPercentage */
}

.legend-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1em;
  margin-left: 7vw;
}

.legend-item {
  display: flex;
  align-items: center;
  padding-left: 1vw;
}

.legend-bar {
  width: 3vw;
  height: 0.5em;
  margin-right: 0.5em;
}

.md-field-filtr {
  max-width: 350px;
}

.select-filter {
  padding-left: calc(24px + 3vh);
}

.select-and-help-container {
  display: flex;
  align-items: center;
  /* This ensures vertical alignment */
}

.help-icon {
  cursor: pointer;
  /* If you want it to indicate it's clickable */
  margin-left: 1rem;
  /* Spacing between the select and the icon, adjust as needed */
}

.md-dialog {
  max-width: 500px;
  /* Ограничение ширины диалогового окна */
  margin: auto;
  /* Центрирование диалога на странице */
}
</style>