<template>
  <div class="progress-ring">
    <svg class="progress-ring__svg">
      <circle
        class="progress-ring__circle--background"
        stroke="#E2E2E2"
        stroke-width="15"
        fill="transparent"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
      />
      <circle
        class="progress-ring__circle--progress"
        :stroke-dasharray="circumference + ' ' + circumference"
        :style="{ strokeDashoffset: strokeDashoffset + 'px' }"
        stroke="url(#gradient)"
        stroke-width="15"
        stroke-linecap="round" 
        fill="transparent"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
      />
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style="stop-color:#00B4D8; stop-opacity:1" />
          <stop offset="100%" style="stop-color:#90E0EF; stop-opacity:1" />
        </linearGradient>
      </defs>
    </svg>
    <div class="progress-ring__content">
      <span class="progress-ring__percent">{{ percent }}%</span>
      <span class="progress-ring__label"><br>задач<br>выполнено</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percent: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      radius: 150,
      strokeWidth: 12
    };
  },
  computed: {
    normalizedRadius() {
      return this.radius - this.strokeWidth * 2;
    },
    circumference() {
      return this.normalizedRadius * 2 * Math.PI;
    },
    strokeDashoffset() {
      return this.circumference - this.percent / 100 * this.circumference;
    }
  },
  watch: {
    percent() {
      this.updateCircle();
    }
  },
  methods: {
    updateCircle() {
      this.strokeDashoffset = this.circumference - this.percent / 100 * this.circumference;
    }
  },
  mounted() {
    this.updateCircle();
  }
};
</script>

<style scoped>
.progress-ring {
  position: relative;
  width: 300px; 
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-ring__svg {
  transform: rotate(-90deg);
  position: relative;
  width: 100%;
  height: 100%;
}

.progress-ring__circle--background {
  fill: transparent;
}

.progress-ring__circle--progress {
  fill: transparent;
  transition: stroke-dashoffset 0.3s ease-out;
}

.progress-ring__content {
  position: absolute;
  text-align: center;
}

.progress-ring__percent {
  font-size: 65px;
  font-weight: 400;
}

.progress-ring__label {
  font-size: 20px;
}
</style>
