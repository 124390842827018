<template>
    <el-autocomplete class="inline-input" v-model="input" :fetch-suggestions="querySearch"
        placeholder="Введите имя работника" @select="handleSelect" @input="handleInput">
        <template #append>
            <el-button icon="el-icon-close" @click="resetInput"></el-button>
        </template>
    </el-autocomplete>
</template>

<script>
import axios from 'axios';

export default {
    name: 'EmployeePicker',
    props: {
        selectedDepartment: {
            type: [String, Number, null],
            default: null
        }
    },
    data() {
        return {
            employees: [],
            input: '',
        };
    },
    created() {
        this.fetchEmployees();
    },
    watch: {
        selectedDepartment() {
            this.fetchEmployees(); // Вызовите этот метод при каждом изменении selectedDepartment
        }
    },
    methods: {
        async fetchEmployees() {
            try {
                let url = '/api/users/user/employees/?is_fired=0';
                if (this.selectedDepartment) {
                    url += `&department_id=${this.selectedDepartment}`;
                }
                const response = await axios.get(url);
                this.employees = response.data.map(item => ({
                    value: item.name + ' ' + item.last_name,
                    key: item.id
                }));
            } catch (err) {
                console.error(err);
            }
        },
        querySearch(queryString, cb) {
            const results = this.employees.filter((employee) => {
                return employee.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
            });
            cb(results);
        },
        handleSelect(item) {
            this.$emit('select', item.key);
        },
        handleInput(value) {
            if (!value) {
                this.$emit('select', null);
            }
        },
        resetInput() {
            this.input = '';
            this.$emit('select', null);
        },
    },
};
</script>
