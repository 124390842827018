import axios from 'axios'
import _ from 'lodash'

const axiosConfig = {
    baseURL: 'https://view.drsoft.ru/api/',
    timeout: 700000,
    xsrfHeaderName: "X-CSRFToken",
    xsrfCookieName: "XCSRF-Token",
    withCredentials: true
}

const processErrorResponse = (self, response) => {
    if (response.status == 400) {
        _.forIn(response.data, (errors, field) => {
            _.each(errors, error => {
                self.errors[field] = error
            })
        })
    } else {
        self.$message({
            message: 'Внутренняя ошибка сервера',
            type: 'error'
        })
    }
}

export { processErrorResponse }
export default axios.create(axiosConfig)
