<template>
  <div class="aside-container">
    <md-app-drawer md-permanent="mini">
      <md-list>
        <md-list-item>
          <router-link :to="{ name: 'business' }">
            <img src='@/assets/icons/nav/BiPr.ico' alt="Проекты" style="width: 25px; height: 25px;" />
            <md-tooltip md-direction="right">Проекты</md-tooltip>
          </router-link>
        </md-list-item>

        <md-list-item>
          <router-link :to="{ name: 'statistic' }">
            <img src="@/assets/icons/nav/Statistic.ico" alt="Статистика" style="width: 25px; height: 25px;" />
            <md-tooltip md-direction="right">Статистика</md-tooltip>
          </router-link>
        </md-list-item>

        <md-list-item>
          <router-link :to="{ name: 'organization' }">
            <img src='@/assets/icons/nav/Structure.ico' alt="Структура организации" style="width: 25px; height: 25px;" />
            <md-tooltip md-direction="right">Структура организации</md-tooltip>
          </router-link>
        </md-list-item>

        <md-list-item>
          <el-badge is-dot v-if="showNotification" type="warning" class="item">
            <router-link :to="{ name: 'settings' }">
              <img src='@/assets/icons/nav/Settings.ico' alt="Настройки" style="width: 25px; height: 25px;" />
              <md-tooltip md-direction="right">Настройки</md-tooltip>
            </router-link>
          </el-badge>
          <router-link v-else :to="{ name: 'settings' }">
            <img src='@/assets/icons/nav/Settings.ico' alt="Настройки" style="width: 25px; height: 25px;" />
            <md-tooltip md-direction="right">Настройки</md-tooltip>
          </router-link>
        </md-list-item>
      </md-list>

      <div class="logout-section">
        <md-list>
          <md-list-item @click="logout()">
            <img src='@/assets/icons/nav/Exit.ico' alt="Выход" style="width: 25px; height: 25px;" />
            <md-tooltip md-direction="right">Выход</md-tooltip>
          </md-list-item>
        </md-list>
      </div>
    </md-app-drawer>
  </div>
</template>



<script>
import { mapGetters } from "vuex";

export default {
  name: "Aside",
  data() {
    return {
      showNotification: false,
    };
  },
  mounted() {
    this.checkSubscriptionExpiry();

    // Запустить проверку каждые 10 минут
    setInterval(() => {
      this.checkSubscriptionExpiry();
    }, 600000); // 600000 миллисекунд = 10 минут
  },
  computed: {
    ...mapGetters("me", {
      me: "me",
      isLoggedIn: "isLoggedIn",
    }),
  },
  methods: {
    async checkSubscriptionExpiry() {
      try {
        const response = await this.$axios.get("/subscription/subscription/current/");
        const expirationDate = new Date(response.data.expires);
        const currentDate = new Date();
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const diffDays = Math.round(Math.abs((expirationDate - currentDate) / oneDay));

        if (diffDays < 7) {
          this.showNotification = true;
        }
      } catch (error) {
        console.error("Error checking subscription expiry:", error);

        // Check for 404 response status and show notification
        if (error.response && error.response.status === 404) {
          this.showNotification = true;
        }
      }
    },
    logout() {
      this.$axios
        .post("/auth/logout/")
        .then(() => {
          this.$store.dispatch("me/clear");
          window.location = "/auth";
        });
    },
  },
};
</script>

<style scoped>
.aside-container {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 9999;
}

.md-app-drawer {
  background-color: #002586 !important;
  width: 65px;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.md-tooltip {
  font-size: 1.2rem;
  margin-left: 5px;
}

.logout-section {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.md-icon {
  color: #ffffff;
}

.md-list-item:hover .md-icon {
  color: #90E0EF !important;
}

.md-list-item {
  background-color: #002586;
}

.md-list-item:hover {
  background-color: #002586;
}

.md-list {
  background-color: #002586 !important;
}

.md-divider {
  background-color: #002586;
  border-color: #002586;
}

.md-list-item:not(:last-child) {
  border-bottom: 1px solid #002586;
}
</style>
