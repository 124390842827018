<template>
    <div class="container-settings">
        <h2 class="md-title" style=" padding-left: 10px;">Настройки профиля</h2>
        <div v-if="!showProfileSettings && !showPasswordChange && !showSubscription && !showDetails && !showAccountsManagement">
            <md-button class="md-dense md-raised md-primary sub-but" @click="showSubscription = true">
                <span class="light-md-button">Моя подписка</span>
            </md-button>
            <md-button class="md-dense md-primary button-set notUpper-md-button" @click="showAccountsManagement = true">
                <span class="light-md-button">Управление аккаунтами</span>
            </md-button>
            <md-button class="md-dense md-primary button-set notUpper-md-button" @click="showDetails = true">
                <span class="light-md-button">Реквизиты</span>
            </md-button>
        </div>
        <div v-if="!showProfileSettings && !showPasswordChange && !showSubscription && !showDetails && !showAccountsManagement"
            class="grid-container-p">
            <div class="grid-item"><span class="static-line">Логин</span></div>
            <div class="grid-item">{{ user.email }}</div>

            <div class="grid-item"><span class="static-line">Ключ</span></div>
            <div class="grid-item">{{ user.webhook }}</div>

            <div class="grid-item"><span class="static-line">Токен приложения "Битрикс 24"</span></div>
            <div class="grid-item">{{ user.dragon_token }}</div>

            <div class="grid-item"><span class="static-line">Имя</span></div>
            <div class="grid-item">{{ user.first_name }}</div>

            <div class="grid-item"><span class="static-line">Фамилия</span></div>
            <div class="grid-item">{{ user.last_name }}</div>

            <div class="grid-item"><span class="static-line">Телефон</span></div>
            <div class="grid-item">{{ user.phone }}</div>
        </div>
        <div v-if="!showProfileSettings && !showPasswordChange && !showSubscription && !showDetails && !showAccountsManagement">
            <md-button class="md-dense md-primary button-set" @click="showProfileSettings = true">
                <span class="light-md-button">Редактировать профиль</span>
            </md-button>
            <md-button class="md-dense md-primary button-set" @click="showPasswordChange = true">
                <span class="light-md-button">Сменить пароль</span>
            </md-button>
        </div>
        <ProfileSettings v-if="showProfileSettings" :user="user" @close="showProfileSettings = false" />
        <PasswordChange v-if="showPasswordChange" :password="password" @close="showPasswordChange = false" />
        <Subscription v-if="showSubscription" :subscription="subscription" @close="showSubscription = false" />
        <CompanyDetails v-if="showDetails" :company="company" @close="showDetails = false" />
        <AccountManagement v-if="showAccountsManagement" @close="showAccountsManagement = false" />

    </div>
</template>


<script>
import axios from 'axios';

import ProfileSettings from './ProfileSettings.vue';
import PasswordChange from './PasswordChange.vue';
import Subscription from './Subscription.vue';
import CompanyDetails from './CompanyDetails.vue';
import AccountManagement from './AccountManagement.vue';

export default {
    name: 'Settinguser',
    components: {
        ProfileSettings,
        PasswordChange,
        Subscription,
        CompanyDetails,
        AccountManagement
    },
    data() {
        return {
            user: {
                first_name: null,
                last_name: null,
                webhook: null,
                email: null,
                phone: null
            }, // данные профиля
            showProfileSettings: false, // показать модальное окно редактирования профиля
            showPasswordChange: false, // показать модальное окно смены пароля
            showSubscription: false,
            showDetails: false,
            showAccountsManagement: false
        };
    },
    watch: {
        showProfileSettings(value) {
            if (value) this.showPasswordChange = false;
        },
        showPasswordChange(value) {
            if (value) this.showSubscription = false;
        },
        showSubscription(value) {
            if (value) this.showDetails = false;
        },
        showDetails(value) {
            if (value) this.showProfileSettings = false;
        },

    },
    mounted() {
        this.fetchProfileData();
        console.log('CompanyDetails is mounted');
    },
    methods: {
        fetchProfileData() {
            axios.get('/api/users/user/me/')
                .then(response => {
                    this.user.email = response.data.email;
                    this.user.first_name = response.data.first_name;
                    this.user.last_name = response.data.last_name;
                    this.user.phone = response.data.phone;
                    this.user.webhook = response.data.webhook;
                    this.user.dragon_token = response.data.dragon_token;
                })
                .catch(error => {
                    console.error(error);
                });
        },
    },
};
</script>

<style>
.grid-container-p {
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
    row-gap: 2rem;
    padding-left: 10px;
    max-width: 45vw;
    padding-top: 4vh;
}

.container-settings {
    padding-left: 3vh;
    gap: 1vh;
}

.grid-item {
    /* Стили для элементов сетки */
}


.my-font-class {
    font-family: 'Gilroy-Light', sans-serif !important;
}

.static-line {
    color: #767676;
}

.sub-but {
    border-radius: 5px;
    text-transform: none;
}
</style>