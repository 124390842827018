<template>
  <div class="md-layout">
    <div v-for="(item, index) in blocks" :key="index" :style="{ backgroundColor: `#${item.color}` }"
      class="md-elevation-2 md-layout-item">
      <div class="column">
        <div v-html="item.text" class="md-subheading text"></div>
        <div class="md-headline count" v-if="item.count !== undefined">{{ item.count }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      blocks: [
        // { color: '00B4D8', text: 'Бизнес-процессов<br>всего', key: 'total' },
        { color: '40C3E3', text: 'Проектов<br>выполняется', key: 'in_progress' },
        { color: '81D5EB', text: 'Проектов<br>просрочено', key: 'overdue' },
        { color: '90E0EF', text: 'Проектов<br>завершено', key: 'closed' },
        { color: 'CAF0F8', text: 'Проектов<br>завершено<br>с просрочкой', key: 'closed_overdue' },
      ],
    };
  },
  created() {
    axios.get('/api/process/processes/general_info/')
      .then(response => {
        const data = response.data;
        this.blocks.forEach((block, index) => {
          const count = data[block.key] || 0;
          this.$set(this.blocks[index], 'count', count);
        });
      })
      .catch(error => {
        console.log(error);
      });
  },
};
</script>
  
<style scoped>
.md-layout {
  display: flex;
  /* justify-content: space-between; */
}

.md-layout-item {
  width: 250px;
  height: 150px;
  margin: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  position: relative;
  border-radius: 10px;
  box-sizing: border-box;
  max-width: 310px;
}

.column {
  display: flex;

  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.text {
  text-align: right;
  font-size: 120%;
  padding-right: 10px;
}

@media (min-width: 1921px) {
  .text {
    font-size: 150%;
  }
}

.count {
  font-size: 500%;
}
</style>
  