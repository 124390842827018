import _ from 'lodash';
import axios from '@/axios'

const CUSTOMER = 1
const MAIN_CONTRACOR = 2
const CONTRACTOR = 3
const INVESTOR = 4
const SUPERVISOR = 5

export default {
    namespaced: true,
    state: {
        me: {membership: [], current_company: {}},
    },
    getters: {
        me: (state) => {
            return state.me
        },
        currentCompany: (state, getters) => {
            if (!getters.isLoggedIn) {
                return {}
            }
            if (!state.me.current_company.id) {
                return {}
            }
            let currentCompany = state.me.current_company
            _.each(state.me.membership, v => {
                if (v.company.id === currentCompany.id) {
                    currentCompany.is_employee = v.is_employee
                }
            })
            return currentCompany
        },
        currentBuilding: (state, getters) => {
            if (!getters.isLoggedIn) {
                return {}
            }
            if (!state.me.current_building.id) {
                return {}
            }
            return state.me.current_building
        },
        isCustomer: (state, getters) => {
            return getters.currentCompany.status == CUSTOMER;
        },
        isMainContractor: (state, getters) => {
            return getters.currentCompany.status == MAIN_CONTRACOR;
        },
        isContractor: (state, getters) => {
            return getters.currentCompany.status == CONTRACTOR;
        },
        isInvestor: (state, getters) => {
            return getters.currentCompany.status == INVESTOR;
        },
        isSupervisor: (state, getters) => {
            return getters.currentCompany.status == SUPERVISOR;
        },
        isEmployee: (state, getters) => {
            return getters.currentCompany.is_employee;
        },
        isOwner: (state, getters) => {
            let ownCompany = null;
            _.each(getters.me.membership, v => {
                if (v.position == null && v.department == null && !v.is_employee) {
                    ownCompany = v
                }
            })
            if (!ownCompany) {
                return false
            }
            return ownCompany.company.id == getters.currentCompany.id
        },
        isBuildingOwner: (state, getters) => {
            return getters.isOwner && !getters.me.current_participant.status
        },
        hasPerm: (state) => (perm) => {
            return state.me.perms.indexOf(perm) >= 0
        },
        isLoggedIn: (state) => {
            return state.me.id != null
        }
    },
    mutations: {
        SET_USER(state, payload) {
            state.me = payload
        },
        SET_CURRENT_COMPANY(state, payload) {
            state.me.current_company = payload
        },
        SET_CURRENT_BUILDING(state, payload) {
            state.me.current_building = payload
        },
        CLEAR_USER(state) {
            state.me = {membership: [], current_company: {}}
        }
    },
    actions: {
        GET: async (context) => {
            let {data} = await axios.get('/users/user/me/');
            context.commit("SET_USER", data)
        },
        SET_CURRENT_COMPANY: (context, payload) => {
            context.commit('SET_CURRENT_COMPANY', payload)
        },
        SET_CURRENT_BUILDING: (context, payload) => {
            context.commit('SET_CURRENT_BUILDING', payload)
        },
        set({commit}, user) {
            commit('SET_USER', user);
        },
        UPDATE_USER({commit}) {
            commit('SET_USER');
        },
        clear({commit}) {
            commit('CLEAR_USER');
        }
    }
}