<template>
    <div class="account-management">
        <div v-if="!showAddUserForm && !showEditUserForm">
            <div v-for="(email, index) in emails" :key="email.id" class="grid-container-a">
                <div class="grid-item align-left">
                    <span class="static-line">{{ index + 1 }}</span>
                </div>
                <div class="grid-item">{{ email.email }}</div>
                <div class="grid-item align-right" v-if="email.email">
                    <md-button @click="showEditForm(email)">
                        <span
                        class="notUper-light-md-button">Редактировать</span>
                    </md-button>
                </div>
            </div>
            <md-button class="md-dense md-primary button-add notUpper-md-button" @click="showAddForm">Добавить пользователя</md-button>

            <md-button class="md-dense md-primary button-set notUpper-md-button" @click="closeAccountManagement">Закрыть</md-button>
        </div>
        <AddUserForm v-if="showAddUserForm" @close="showAddUserForm = false"></AddUserForm>
        <EditUserForm v-if="showEditUserForm" :userData="selectedUser" @close="showEditUserForm = false"
            @user-deleted="userDeletedHandler"></EditUserForm>
    </div>
</template>
  
<script>
import axios from 'axios';
import AddUserForm from './AddUserForm.vue';
import EditUserForm from './EditUserForm.vue';


export default {
    name: 'AccountManagement',
    components: {
        AddUserForm,
        EditUserForm
    },
    data() {
        return {
            emails: [],
            showAddUserForm: false,
            showEditUserForm: false,
            selectedEmail: null
        };
    },
    mounted() {
        this.fetchEmails();
    },
    methods: {
        async fetchEmails() {
            try {
                const response = await axios.get('/api/users/ceo_employees/');
                this.emails = response.data.result;
            } catch (error) {
                console.error('Error fetching emails:', error);
            }
        },
        showAddForm() {
            this.showAddUserForm = true;
        },
        showEditForm(user) {
            this.selectedUser = user;
            this.showEditUserForm = true;
        },
        closeAccountManagement() {
            this.$emit('close');
        },
        delsucs() {
            this.$notify({
                title: 'Success',
                message: 'Этот аккаунт был удалён',
                type: 'success'
            });
        },
        userDeletedHandler() {
            this.delsucs();  // Покажите уведомление об успешном удалении
            this.fetchEmails();  // Обновите список пользователей
        },
    }
}
</script>

<style scoped>
.account-management {
    max-width: 35vw;
}

.grid-container-a {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 1rem;
    row-gap: 2rem;
    padding-left: 10px;
    max-width: 45vw;
    padding-bottom: 2vh;
}

.grid-item {
    display: flex;
    align-items: center;
}

.grid-item.align-left {
    justify-content: left;
}

.grid-item.align-right {
    justify-content: right;
}
</style>
