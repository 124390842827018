<template>
    <div class="add-management">
        <div class="worker">
            <md-field>
                <label>Email</label>
                <md-input v-model="email"></md-input>
            </md-field>

            <md-field>
                <label for="department">Отдел</label>
                <md-select v-model="selectedDepartment" name="department" id="department" multiple>
                    <md-option v-for="dept in departments" :key="dept.id" :value="dept.id">{{ dept.name }}</md-option>
                </md-select>
            </md-field>

            <md-field>
                <label for="employee">Имя работника</label>
                <md-select v-model="selectedEmployee" name="employee" id="employee">
                    <md-option v-for="emp in employees" :key="emp.id" :value="emp.id">{{ emp.name }}</md-option>
                </md-select>
            </md-field>
        </div>

        <md-button class="md-dense md-primary button-add notUpper-md-button" @click="submitForm">Отправить</md-button>
        <md-button class="md-dense md-primary button-set notUpper-md-button" @click="closeForm">Закрыть</md-button>
    </div>
</template>
  
<script>
import axios from 'axios';

export default {
    data() {
        return {
            email: '',
            selectedDepartment: '',
            departments: [],
            selectedEmployee: '',
            employees: []
        };
    },
    mounted() {
        this.fetchDepartments();
        this.fetchEmployees();
    },
    methods: {
        async fetchDepartments() {
            try {
                const response = await axios.get('/api/organization/structure/as_list/');
                this.departments = response.data;
            } catch (error) {
                console.error('Error fetching departments:', error);
            }
        },
        async fetchEmployees() {
            try {
                const response = await axios.get('/api/users/user/employees/?is_fired=0');
                this.employees = response.data;
            } catch (error) {
                console.error('Error fetching employees:', error);
            }
        },
        async submitForm() {
            try {
                await axios.post('/api/users/ceo_employees/', {
                    email: this.email,
                    department: this.selectedDepartment,
                    employee: this.selectedEmployee
                });
                this.$emit('close');
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        },
        closeForm() {
            this.$emit('close');
        }
    }
};
</script>
  
<style scoped>
.add-management {
    max-width: 25vw;
}

.worker {
    padding-left: 10px;
}
</style>