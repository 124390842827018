<template>
  <div>
    <div class="grid-item" style="margin-left: 2vw; margin-top: 1vh;">
      <employee-picker @select="handleEmployeeSelect" @reset-data="resetDataD"></employee-picker>
    </div>
    <statistic-process-list :user-id="selectedUserId" :tasks="tasksTotal"
      style="justify-content: center;"></statistic-process-list>
    <div class="center-content">
      <div class="row-content">
        <div class="center-content">
          <progress-ring :percent="completedPercentage"></progress-ring>
          <arrow-percentage-component :data="{
        direction: comparedToPreviousDirection,
        percentage: Math.abs(comparedToPreviousRounded),
        timespan: 'year'
      }" />
        </div>
        <div class="graph-container">
          <ChartComponentyear :data="chartData" :key="chartKey" :user-id="selectedEmployee.id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import StatisticProcessList from './StatisticProcessListYear.vue';
import ProgressRing from './ProgressRing.vue';
import ArrowPercentageComponent from './ArrowPercentageComponent.vue';
import ChartComponentyear from './ChartComponentYear.vue';
import EmployeePicker from './EmployeePicker.vue';

export default {
  data() {
    return {
      selectedEmployee: {},
      tasksTotal: {},
      completedPercentage: 0,
      comparedToPreviousDirection: 'down',
      comparedToPreviousRounded: 0,
      chartData: {},
      chartKey: 0,
      selectedUserId: null,
    };
  },
  components: {
    StatisticProcessList,
    ProgressRing,
    ArrowPercentageComponent,
    ChartComponentyear,
    EmployeePicker
  },
  methods: {
    handleEmployeeSelect(employeeId) {
      console.log('Employee ID selected from EmployeePicker:', employeeId);
      this.selectedUserId = employeeId;
      this.selectedEmployee = { id: employeeId }; // создаем объект employee с ID
      this.fetchData(employeeId); // передаем ID напрямую
      this.chartKey++;
    },
    async fetchData(userId = null) {
      let url = 'api/statistics/statistics/tasks_totals/?timespan=year';
      if (userId) {
        url += `&user_id=${userId}`; // Добавление параметра user_id в URL
      }

      const response = await axios.get(url);
      this.tasksTotal = response.data.tasks_total;
      this.completedPercentage = Math.round(response.data.completed_percentage);
      this.comparedToPreviousDirection = response.data.compared_to_previous > 0 ? 'up' : 'down';
      this.comparedToPreviousRounded = Math.round(response.data.compared_to_previous);
    }
  },
  mounted() {
    this.fetchData();
  }
}
</script>

<style scoped>
.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  /* Добавлено */
}

.row-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  /* Добавлено */
}

.graph-container {
  display: block;
  width: 35vw;
  margin: auto;
  text-align: center;
}
</style>