<template>
    <div class="password-change">
        <md-button class="md-dense md-primary button-set notUpper-md-button" @click="$emit('close')">Закрыть</md-button>
        <div class="prof-input">
            <md-field>
                <label>Новый пароль</label>
                <md-input type="password" v-model="inputPassword.password"></md-input>
            </md-field>
            <md-field>
                <label>Повторите пароль</label>
                <md-input type="password" v-model="inputPassword.rep_password"></md-input>
            </md-field>
        </div>
        
            <md-button class="md-raised md-primary save-but notUpper-md-button" @click="change">Сменить пароль</md-button>

    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'PasswordChange',
        data() {
            return {
                inputPassword: {
                    password: '',
                    rep_password: '',
                }
            }
        },
        methods: {
            change() {
                if (this.inputPassword.password.length < 8) {
                    alert("Пароль должен содержать минимум 8 символов!");
                    return;
                }

                if (this.inputPassword.password !== this.inputPassword.rep_password) {
                    alert("Пароли не совпадают!");
                    return;
                }

                axios.post('/api/auth/password/change/', {
                        new_password1: this.inputPassword.password,
                        new_password2: this.inputPassword.rep_password,
                    })
                    .then(() => {
                        alert("Пароль успешно изменен!");
                        this.$emit('close');
                    })
                    .catch(error => {
                        console.error(error);
                        alert("Произошла ошибка при смене пароля!");
                    });
            },
        },
    };
</script>



<style>
    .password-change {
        max-width: 35vw;
    }

    .prof-input {
        padding-left: 10px;
    }
</style>