<template>
  <div class="md-layout md-gutter container">
    <div class="md-layout-item">
      <el-date-picker v-model="localDateRange" type="daterange" start-placeholder="Начальная дата" :value="dateRange" @input="updateDateRange"
  end-placeholder="Конечная дата">
</el-date-picker>


    </div>
  </div>
</template>

<script>
  export default {
    props: ['dateRange'],
  data() {
    return {
      localDateRange: this.dateRange
    };
  },
  watch: {
    dateRange(newValue) {
      this.localDateRange = newValue;
    },
    localDateRange(newValue) {
      this.$emit('input', newValue);
    }
  },
    methods: {
      dateChange(value) {
        console.log('Date changed:', value);
        this.$emit('update:modelValue', value);
      },
      updateDateRange(newDateRange) {
    this.$emit('input', newDateRange);
  },
      resetData() {
        console.log('Resetting data...');
        this.$emit('reset-data');
      }
    }
  };
</script>