<template>
  <div class="select-filter">
    <md-field class="md-field-filtr">
      <label for="Status">Выберите статус</label>
      <md-select v-model="selectedStatuses" multiple @input="notifyParent">
        <md-option v-for="status in readableStatuses" :key="status" :value="status">
          {{ status }}
        </md-option>
      </md-select>
    </md-field>
    <div class="selected-chips">
      <md-chip v-for="status in selectedStatuses" :key="status" class="md-primary" md-deletable
        @md-delete="removeStatus(status)">
        {{ status }}
      </md-chip>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'StatusFilter',
  props: {
    processId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      selectedStatuses: [],
      statuses: [],
      statusMap: {
        "Новая": 1,
        "В ожидании": 2,
        "Выполняется": 3,
        "Ожидает подтверждения": 4,
        "Завершено": 5,
        "Отложено": 6,
        "Отклонено": 7,
        "Просрочено": 8,
        "Завершено с просрочкой": 9
      }
    };
  },
  created() {
    this.fetchStatuses();
  },
  computed: {
    readableStatuses() {
      //конвертируем числовое значение в смысловое
      return this.statuses.map(statusId => {
        return this.getKeyByValue(this.statusMap, statusId);
      });
    }
  },
  watch: {
    processId: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== undefined && newVal !== oldVal) {
          this.fetchStatuses();
        }
      }
    },
  },
  methods: {
    async fetchStatuses() {
      try {
        const response = await axios.get(`/api/process/processes/${this.processId}/filter_statuses/`);
        this.statuses = response.data.statuses;
      } catch (error) {
        console.error('Error fetching statuses:', error);
      }
    },
    notifyParent() {
      const selectedStatusIds = this.selectedStatuses.map(status => this.statusMap[status]);
      this.$emit('status-change', selectedStatusIds);
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value);
    },
    removeStatus(status) {
      this.selectedStatuses = this.selectedStatuses.filter(s => s !== status);
    }
  }
};
</script>

<style scoped>
.select-filter {
  padding-left: calc(24px + 3vh);
}

.selected-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.md-chip {
  border-radius: 5px;
  background: #CAF0F8;
}

.md-field-filtr {
  max-width: 300px;
}
</style>
  