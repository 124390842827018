<template>
  <div class="graph-container">
    <canvas ref="canvas"></canvas>
    <md-button class="md-icon-button" @click="zoomOut">
      <md-icon>remove</md-icon>
    </md-button>
    <md-button class="md-icon-button" @click="zoomIn">
      <md-icon>add</md-icon>
    </md-button>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import axios from 'axios';
import zoomPlugin from 'chartjs-plugin-zoom';

Chart.register(...registerables, zoomPlugin);

export default {
  props: {
    groupId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  async mounted() {
    await this.fetchGraphData();
  },
  methods: {
    async fetchGraphData() {
      try {
        const response = await axios.get(`/api/graph/graph/process_performance/?process_id=${this.groupId}`);
        this.graphData = response.data;
        this.createChart();
      } catch (error) {
        console.error("Failed to fetch graph data:", error);
      }
    },
    zoomIn() {
      this.chart.zoom(1.1); // Увеличиваем масштаб на 10%
    },
    zoomOut() {
      this.chart.zoom(0.9); // Уменьшаем масштаб на 10%
    },
    createChart() {
      const ctx = this.$refs.canvas.getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'bar', //  тип графика столбчатая диаграмма
        data: {
          labels: this.prepareLabels(this.graphData.graph.plan),
          datasets: [
            {
              label: 'План',
              data: this.graphData.graph.plan.map(item => item[2]),
              backgroundColor: '#CAF0F8',
            },
            {
              label: 'Факт',
              data: this.prepareDataset(this.graphData.graph.real, this.graphData.graph.plan),
              backgroundColor: '#00B4D8',
            },
            {
              label: 'Прогноз',
              data: this.prepareDataset(this.graphData.graph.predicted, this.graphData.graph.plan),
              backgroundColor: '#98ff98',
            }
          ]
        },
        options: {
          interaction: {
            mode: 'index',
            intersect: false,
          },
          scales: {
            y: {
              beginAtZero: true
            }
          },
          plugins: {
            zoom: {
              pan: {
                enabled: true,
                mode: 'x',
                speed: 20, // Уменьшить для более плавного панорамирования
                threshold: 10, // Увеличь порог для начала панорамирования
              },
              limits: {
                x: { min: 'original', max: 'original', minRange: 1 },
              },
              zoom: {
                wheel: {
                  enabled: true,
                  speed: 0.01, // Уменьшить для более плавного масштабирования колесом мыши
                },
                pinch: {
                  enabled: true,
                  speed: 0.01, // Уменьшить для более плавного масштабирования щипком
                },
                mode: 'x',
              }
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  // Получаем значение и метку из контекста
                  var label = context.dataset.label || '';
                  var value = context.parsed.y;
                  // Добавляем к значению знак %
                  return `${label}: ${value} %`;
                },
                afterBody: (context) => {
                  // Здесь мы предполагаем, что у вас есть доступ к данным графика через context
                  let tooltipIndex = context[0].dataIndex;
                  // Ваш массив данных для 'plan', 'real', или 'predicted', в зависимости от того, что выбрано в tooltip
                  let datasetLabel = context[0].dataset.label;
                  let taskName = ''; // Переменная для хранения названия задачи
                  // Определение, из какого массива брать данные
                  if (datasetLabel === 'План') {
                    taskName = this.graphData.graph.plan[tooltipIndex][3] || 'Нет названия задачи';
                  } else if (datasetLabel === 'Факт') {
                    let planIndex = this.graphData.graph.plan.findIndex(item => item[0] === this.graphData.graph.real[tooltipIndex][0]);
                    taskName = planIndex !== -1 ? this.graphData.graph.plan[planIndex][3] : 'Нет названия задачи';
                  } else if (datasetLabel === 'Прогноз') {
                    let planIndex = this.graphData.graph.plan.findIndex(item => item[0] === this.graphData.graph.predicted[tooltipIndex][0]);
                    taskName = planIndex !== -1 ? this.graphData.graph.plan[planIndex][3] : 'Нет названия задачи';
                  }
                  // Возвращаем название задачи для отображения в подсказке
                  return taskName;
                }
              }
            }
          }
        }
      });
    },
    prepareLabels(data) {
      // Фильтрация данных, чтобы исключить начальные точки с нулевыми значениями
      return data.filter((item, index) => this.checkDataPoint(data, index)).map(item => {
        // Получаем дату в формате ISO (YYYY-MM-DD)
        const isoDate = item[1].split('T')[0];
        // Преобразуем ISO дату в формат 'ДД ММ ГГГГ'
        const [year, month, day] = isoDate.split('-');
        // Возвращаем строку в новом формате
        return `${day}-${month}-${year}`;
      });
    },
    prepareDataset(dataset, reference) {
      // Адаптация dataset, исключая данные, соответствующие условию фильтрации
      return reference.filter((item, index) => this.checkDataPoint(reference, index)).map(refItem => {
        const item = dataset.find(d => d[0] === refItem[0]);
        return item ? item[2] : null; // Возвращаем значение или null, если элемент не найден
      });
    },
    checkDataPoint(data, index) {
      // Проверка, является ли точка данным, который стоит включить
      // Исключаем, если все три первые значения null или процент равен 0
      if (index === 0) { // Проверяем только первую точку данных
        const plan = data[0];
        const real = this.graphData.graph.real[0];
        const predicted = this.graphData.graph.predicted[0];

        if ((plan[0] === null && real[0] === null && predicted[0] === null) || (plan[2] === 0 && real[2] === 0 && predicted[2] === 0)) {
          return false; // Исключаем эту точку данных
        }
      }
      return true; // Включаем точку данных
    }
  }
};
</script>

<style></style>
