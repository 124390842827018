<template>
    <div>
        <div v-if="!showEdit">
            <div class="grid-container-p">
                <div class="grid-item"><span class="static-line">Телефон</span></div>
                <div class="grid-item">{{ credentials.phone }}</div>

                <div class="grid-item"><span class="static-line">Название компании</span></div>
                <div class="grid-item">{{ credentials.company_name }}</div>

                <div class="grid-item"><span class="static-line">ИНН</span></div>
                <div class="grid-item">{{ credentials.inn }}</div>

                <div class="grid-item"><span class="static-line">КПП</span></div>
                <div class="grid-item">{{ credentials.kpp }}</div>

                <div class="grid-item"><span class="static-line">Email</span></div>
                <div class="grid-item">{{ credentials.email }}</div>
            </div>
            <div class="button-container">
                <md-button class="md-dense md-raised md-primary sub-but" @click="showEdit = true">
                    <span class="light-md-button">Редактировать</span>
                </md-button>
                <md-button class="md-dense md-primary button-set " @click="$emit('close')">
                    <span class="light-md-button">Закрыть</span>
                </md-button>
            </div>
        </div>
        <CompanyEdit v-else :credentials="credentials" @close="showEdit = false" @save="updateCredentials" />
    </div>
</template>
  
<script>
import axios from 'axios';
import CompanyEdit from './CompanyEdit.vue';

export default {
    components: {
        CompanyEdit
    },
    data() {
        return {
            credentials: {
                phone: '',
                company_name: '',
                inn: '',
                kpp: '',
                email: ''
            },
            showEdit: false
        };
    },
    mounted() {
        this.fetchCompanyDetails();
    },
    methods: {
        fetchCompanyDetails() {
            axios.get('/api/users/credentials/')
                .then(response => {
                    this.credentials = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        updateCredentials(newCredentials) {
            this.credentials = newCredentials;
            setTimeout(() => {
                this.showEdit = false;
            }, 100);
        },
        closeEdit() {
            setTimeout(() => {
                this.showEdit = false;
            }, 100);
        }
    }
};
</script>
  
<style>
/* Можно использовать стили, которые уже описаны в вашем предыдущем коде */
.grid-container-p {
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
    row-gap: 2rem;
    padding-left: 10px;
    max-width: 45vw;
    padding-top: 4vh;
}

.grid-item {
    /* Стили для элементов сетки */
}

.static-line {
    color: #767676;
}
</style>
  