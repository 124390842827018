<template>
    <el-form :model="model" :rules="rules" ref="form" @submit.prevent="submitForm()">
        <div>
            <md-field :class="messageClass">
                <label><span style="text-transform: none;">Логин</span></label>
                <md-input v-model="model.email" :error="errors.email"></md-input>
            </md-field>

            <md-field :class="messageClass">
                <label><span style="text-transform: none;">Пароль</span></label>
                <md-input v-model="model.password" :error="errors.password" type="password"></md-input>
            </md-field>
        </div>
        <span>

        </span>
        <md-card-content class="md-layout md-gutter" :class="`md-alignment-center`" style="padding-left: 40px;">
            <div class="md-layout md-gutter" :class="`md-alignment-center`">
                <div class="md-layout-item ">
                    <md-checkbox class="md-primary" v-model="checked"><span
                            style="text-transform: none; font-family: 'Gilroy-Regular', sans-serif !important;">Запомнить
                            меня</span></md-checkbox>
                </div>
                <div class="md-layout-item">
                    <ForgotPassword style="padding-left: 5rem;" />
                </div>
            </div>
        </md-card-content>
        <md-card-actions class="md-layout md-gutter" :class="`md-alignment-center`">
            <progress-spinner v-if="loading"></progress-spinner>
            <md-button class="md-raised md-primary auth-md-button" type="submit" @click="submitForm($event)"
                >Войти</md-button>
        </md-card-actions>
    </el-form>
</template>

<script>
import _ from 'lodash'

import ForgotPassword from './ForgotPassword.vue'

export default {
    name: 'LoginForm',
    components: {
        ForgotPassword
    },
    data() {
        return {
            model: {
                email: '',
                password: ''

            },
            messageClass: "",
            showCaptcha: "",
            errors: {
                email: '',
                password: ''
            },
            checked: true,
            loading: false,
            rules: {
                email: [{
                    required: true,
                    message: 'Поле email обязательно',
                    trigger: 'change'
                }, {
                    type: 'email',
                    message: 'Введен неправильный email',
                    trigger: 'change'
                }],
                password: [{
                    required: true,
                    message: 'Пароль обязателен',
                    trigger: 'change'
                }, {
                    min: 6,
                    message: 'Пароль должен содержать хотя бы 6 символов символа',
                    trigger: 'change'
                }]
            }
        }
    },
    methods: {
        submitForm() {
            event.preventDefault();
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true
                    this.$axios.post('/auth/login/', {
                        'email': this.model.email,
                        'password': this.model.password
                    })
                        .then(() => {
                            if (localStorage.getItem('nextPageAfterAuth')) {
                                this.$router.push({
                                    'path': localStorage.getItem('nextPageAfterAuth')
                                })
                                localStorage.clear()
                            } else {
                                this.$router.push({
                                    'name': 'business'
                                })
                            }
                        })
                        .catch(({ response }) => {
                            if (response.status == 400) {
                                let errorMessages = {}
                                let firstErrorFound = false;  // флаг для проверки, была ли уже обработана первая ошибка

                                _.forIn(response.data, (errors, field) => {
                                    if (firstErrorFound) return;  // Прекратить итерацию, если мы уже обработали ошибку

                                    _.each(errors, error => {
                                        if (firstErrorFound) return false;  // Прекратить итерацию для внутреннего цикла

                                        errorMessages[field] = error
                                        if (field === 'email') {
                                            this.$message.error(error)
                                        } else if (field === 'password') {
                                            this.$message.error(error)
                                        }

                                        firstErrorFound = true;  // установить флаг в true, так как мы обработали первую ошибку
                                    })
                                });
                                this.errors = errorMessages
                            } else if (response.status == 404) {
                                this.errors['email'] = 'Пользователь с таким email не найден'
                                this.$message.error('Пользователь с таким email не найден')
                            } else {
                                this.$message({
                                    message: 'Внутренняя ошибка сервера',
                                    type: 'error'
                                })
                            }
                        })
                        .then(() => {
                            this.loading = false
                        })
                }

                return false
            })
        }

    }
}
</script>
<style lang="scss" scoped>
.md-checkbox {
    display: flex;
    //color: #002586;
}
</style>