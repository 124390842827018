<template>
  <div>
    <md-button class="md-dense md-primary button-set task-but" @click="closeComponent">
      <span style="font-family: 'Gilroy-Light', sans-serif !important;">Закрыть</span>
    </md-button>
    <md-button v-if="showTaskDetails" class="md-dense md-primary button-set task-but" @click="showTaskComponentAgain">
      <span style="font-family: 'Gilroy-Light', sans-serif !important;">Вернуться</span>
    </md-button>
    <el-tree :props="treeProps" :load="loadNode" lazy accordion class="tree" @node-click="handleNodeClick">
      <template #default="{ node, data }">
        <div class="tree-node">
          <span>{{ data.label }}</span>
          <span class="status-indicator" :style="{ backgroundColor: getStatusColor(data.status) }"></span>
        </div>
      </template>
    </el-tree>
    <TaskComponent v-if="showTaskComponent" :taskData="selectedTask" :taskId="taskId" />
    <TaskDetails v-if="showTaskDetails" :task="selectedTask" :initialData="taskDetailsData" />
  </div>
</template>

<script>
import axios from "axios";
import TaskDetails from '@/components/Task/TaskDetails.vue';
import TaskComponent from '@/components/Task/TaskComponent.vue';

export default {
  components: {
    TaskDetails,
    TaskComponent
  },
  props: {
    taskId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      treeProps: {
        label: 'label',
        children: 'zones',
        isLeaf: 'isLeaf'
      },
      subTasks: [],
      taskDetailsData: [],
      initialSubTaskIds: [],
      initialSubTasksLoaded: false,
      tasks: [],
      isTreeExpanded: false, // Изначально дерево не развернуто
      selectedTask: null, // Выбранная задача
      showTaskDetails: false, // Флаг для отображения TaskDetails
      showTaskComponent: true,
      canCallHandleNodeClick: true, 
    };
  },
  created() {
    this.loadInitialNode();
    this.fetchInitialData();
    this.initializeSelectedTask();
  },
  watch: {
    isTreeExpanded(newVal) {
      console.log("isTreeExpanded изменен: ", newVal);
    },
  },
  methods: {
    fetchInitialData() {
      this.fetchData(this.taskId)
        .then(data => this.taskDetailsData = data)
        .catch(error => this.handleError(error));
    },
    loadInitialNode() {
      this.loadNode({ level: 0 }, () => { });
    },
    handleNodeClick(data) {
      // Проверяем, был ли метод вызван в результате клика по узлу дерева,
      // а не в результате возвращения к TaskComponent
      if (!this.canCallHandleNodeClick) {
        if (this.selectedTask && this.selectedTask.id === data.id) {
          this.isTreeExpanded = !this.isTreeExpanded;
        } else {
          this.isTreeExpanded = true;
          this.selectedTask = data;
        }

        // Переключаем между TaskDetails и TaskComponent
        this.showTaskComponent = false;
        this.showTaskDetails = this.isTreeExpanded;
      }

      // Сброс флага после обработки клика
      this.canCallHandleNodeClick = false;
    },

    showTaskComponentAgain() {
    this.showTaskDetails = false;
    this.showTaskComponent = true;

    // Сбрасываем флаг, чтобы разрешить следующий вызов handleNodeClick
    this.canCallHandleNodeClick = true;
  },
    initializeSelectedTask() {
      // Здесь вы можете установить начальные данные для selectedTask
      // Например, выбрать первую задачу из списка или установить какие-то статические данные
      this.selectedTask = { /* начальные данные задачи */ };
    },
    async fetchData(id) {
      const response = await axios.get(`/api/works/works/get_children/`, { params: { id } });
      return response.data.map(task => ({
        label: task.title,
        id: task.id,
        isLeaf: !task.has_children,
        status: task.status
      }));
    },
    async loadNode(node, resolve) {
      if (node.level === 0) {
        this.fetchData(this.taskId)
          .then(data => {
            if (!this.initialSubTasksLoaded) {
              this.initialSubTaskIds = data.map(task => task.id);
              this.initialSubTasksLoaded = true;
            }
            resolve(data);
          })
          .catch(() => resolve([]));
      } else {
        this.fetchData(node.data.id)
          .then(data => resolve(data))
          .catch(() => resolve([]));
      }
    },
    getStatusColor(status) {
      let borderColor = "#000"; // по умолчанию
      switch (status) {
        case "Новая":
          borderColor = "#5A9"; // светло зелёный
          break;
        case "В ожидании":
          borderColor = "#FFD700"; // жёлтый
          break;
        case "Выполняется":
          borderColor = "#46D2C3"; // синезелёный
          break;
        case "Ожидает подтверждения":
          borderColor = "#FFA500"; // ораньжевый
          break;
        case "Завершено":
          borderColor = "#008000"; //зелёный
          break;
        case "Отложено":
          borderColor = "#696969"; // серый
          break;
        case "Отклонено":
          borderColor = "#B22222"; // кирпич
          break;
        case "Просрочено":
          borderColor = "#FF0000"; // красный
          break;
        case "Завершено с просрочкой":
          borderColor = "#8B0000"; // тёмнокрасный
          break;
        default:
          break;
      }
      return borderColor;
    },
    closeComponent() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.tree {
  background-color: transparent;
  font-size: 16px;
  margin-left: 2vh;
}

.tree-node {
  display: flex;
  align-items: center;
}

.status-indicator {
  display: inline-block;
  margin-left: 10px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.task-but {
  margin-left: 3vh;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active в версии 2.1.8+ */
  {
  opacity: 0;
}
</style>