<template>
    <div class="organization-details" v-if="isActive">
        <h2 class="md-headline">{{ details.name }}</h2>
        <md-button class="md-dense md-primary button-set notUpper-md-button" @click="$emit('close')">Назад</md-button>
        <div class="content-wrapper">
            <div class="department-stats">
                <ul>
                    <li><span class="label">Всего</span> <span class="value">{{ departmentStats.total }}</span></li>
                    <li><span class="label">Закрыто</span> <span class="value">{{ departmentStats.closed }}</span></li>
                    <li><span class="label">Закрыто с просрочкой</span> <span class="value">{{
        departmentStats.closed_overdue }}</span></li>
                    <li><span class="label">Просрочено</span> <span class="overdue-value value">{{
        departmentStats.overdue
    }}</span></li>
                </ul>
            </div>
            <div v-if="isLoading">
                <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
            </div>
            <div v-else>
                <div v-for="(staff, index) in firstStaff" :key="index" class="staff-info">
                    <div class="staff-details-firstStaff" style="box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.05);">
                        <div class="firts-staff-name">
                            <span class="md-subheading staff-name">{{ staff.name }} {{ staff.surname }}</span><br>
                            <span class="md-caption">{{ staff.work_position }}</span><br>
                            <span class="md-subheading staff-kpi">Производительность: {{ staff.kpi }}%</span>
                        </div>
                        <div class="staff-tasks">
                            <ul>
                                <li>Всего: {{ staff.tasks.total }}</li>
                                <li>Закрыто: {{ staff.tasks.closed }}</li>
                                <li>Закрыто с просрочкой: {{ staff.tasks.closed_overdue }}</li>
                                <li>Просрочено: <span class="overdue-value">{{ staff.tasks.overdue }}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <ul class="staff-list" v-if="otherStaff.length > 0">
                    <li v-for="(staff, index) in otherStaff" :key="index" class="staff-info">
                        <div class="staff-details">
                            <div>
                                <span class="md-subheading staff-name">{{ staff.name }}
                                    {{ staff.surname }}</span><br>
                                <span class="md-caption">{{ staff.work_position }}</span><br>
                                <span class="md-subheading staff-kpi">Производительность: {{ staff.kpi }}%</span>
                            </div>
                            <div class="staff-tasks">
                                <ul>
                                    <li>Всего: {{ staff.tasks.total }}</li>
                                    <li>Закрыто: {{ staff.tasks.closed }}</li>
                                    <li>Закрыто с просрочкой: {{ staff.tasks.closed_overdue }}</li>
                                    <li>Просрочено: <span class="overdue-value">{{ staff.tasks.overdue }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
                <div v-else>
                    <p>Работников не найдено</p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'OrganizationDetails',
    props: {
        details: Object,
        departmentId: Number,
        isActive: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            staffData: [],
            isLoading: false,
        };
    },
    computed: {
        departmentStats() {
            return this.staffData.reduce(
                (acc, staff) => {
                    acc.total += staff.tasks.total;
                    acc.closed += staff.tasks.closed;
                    acc.closed_overdue += staff.tasks.closed_overdue;
                    acc.overdue += staff.tasks.overdue;
                    return acc;
                }, {
                total: 0,
                closed: 0,
                closed_overdue: 0,
                overdue: 0,
            }
            );
        },
        firstStaff() {
            return this.staffData.slice(0, 1);
        },
        otherStaff() {
            return this.staffData.slice(1);
        }


    },
    watch: {
        departmentId: {
            immediate: true,
            handler: function () {
                this.fetchStaffData();
            },
        },
    },
    methods: {
        async fetchStaffData() {
            this.isLoading = true;
            try {
                const response = await axios.get(`/api/organization/structure/get_staff/`, {
                    params: {
                        department_id: this.departmentId,
                    },
                });
                this.staffData = response.data;
            } catch (error) {
                console.error('Ошибка при получении данных сотрудников:', error);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>


<style scoped>
.organization-details {
    width: 40vw;
    padding-left: 2rem;
    padding-right: 2rem;
    z-index: 100;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.overdue-value {
    color: #FA7D75;
    font-weight: bold;
}

.department-stats ul {
    list-style: none;
    padding-left: 0;
    border-radius: 10px;
    background: #CAF0F8;
    box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.05);
}

.department-stats li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    /* Добавлено для дополнительного пространства вокруг элементов списка */
}

.department-stats {
    max-width: 400px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    /* Чтобы элементы списка шли вертикально */
    justify-content: center;
    /* Центрирование содержимого по вертикали */
    align-items: center;
    /* Центрирование содержимого по горизонтали */
}

.department-stats ul {
    list-style: none;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    /* Чтобы список занимал всю ширину родительского блока */
    border-radius: 10px;
    background: #CAF0F8;
    box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.05);
}

.department-stats li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    /* Дополнительное пространство вокруг элементов списка */
}

/* Удалить маркеры у элементов списка */
.staff-tasks ul,
.staff-list ul {
    list-style: none;
    padding-left: 0;

}

/* Применить общие стили к блокам */
.staff-details,
.staff-list .staff-info {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.05);
    margin-bottom: 15px;
    /* Добавить отступ снизу для разделения информации о разных сотрудниках */
    padding: 10px;
    /* Добавить внутренний отступ для лучшего вида */
    list-style: none;
}

/* Расположить содержимое в две колонны */
.staff-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-shadow: none;
}

.staff-details-firstStaff {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.05);
    margin-bottom: 15px;
    /* Добавить отступ снизу для разделения информации о разных сотрудниках */
    padding: 20px;
    /* Добавить внутренний отступ для лучшего вида */
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-shadow: none;

}

/* .staff-details > div {
    width: 48%; /* Задайте ширину меньше 50%, чтобы оставить небольшой промежуток между колонками 
} */
.staff-name {
    font-size: 1.2em;
    /* Настройте размер по вашему усмотрению */
}

.staff-kpi {
    color: #002586;
}

.firts-staff-name {
    padding-right: 10px;
    padding-right: 10px;
}


</style>