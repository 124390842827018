<template>
  <div  style="border-radius: 10px;">
    <md-button class="md-primary custom-button notUpper-md-button"><span>Забыли пароль?</span></md-button>
    <md-dialog :md-active.sync="isDialogVisible">
      <md-dialog-title>Восстановление пароля</md-dialog-title>
      <md-dialog-content>
        <p>На указанный e-mail будет отправлено письмо со ссылкой для сброса пароля</p>
        <md-field :class="messageClass">
          <label>Email</label>
          <md-input v-model="model.email" :error="errors.email"></md-input>
        </md-field>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-raised md-primary primary-md-button">Отмена</md-button>
        <md-button class="md-raised md-primary primary-md-button">Отправить</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
name: 'ForgotPasswordForm',
data() {
  return {
    messageClass: "",
    isDialogVisible: false,
    model: {
      email: ''
    },
    errors: {
      email: ''
    },
    loading: false,
    rules: {
      email: [
        {
          required: true,
          message: 'Поле email обязательно',
          trigger: 'change'
        },
        {
          type: 'email',
          message: 'Введен неправильный email',
          trigger: 'change'
        }
      ]
    }
  }
},
methods: {
  isEmpty(value) {
    return !value || value.trim().length === 0;
  },
  isValidEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  },
  submitForm() {
    this.errors.email = '';
    if (this.isEmpty(this.model.email)) {
      this.errors.email = 'Поле email обязательно';
      return;
    }
    if (!this.isValidEmail(this.model.email)) {
      this.errors.email = 'Введен неправильный email';
      return;
    }

    this.loading = true;
    this.$axios
      .post('/auth/forgot-password/restore/', {
        email: this.model.email
      })
      .then(() => {
        this.isDialogVisible = false
      })
      .catch(({ response }) => {
        if (response.status == 400) {
          let errorMessages = {}
          _.forIn(response.data, (errors, field) => {
            _.each(errors, error => {
              errorMessages[field] = error
            })
          })
          this.errors = errorMessages
        } else if (response.status == 404) {
          this.errors['email'] = 'Пользователь с таким email не найден'
        } else {
          this.$message({
            message: 'Внутренняя ошибка сервера',
            type: 'error'
          })
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
}

}
</script>
