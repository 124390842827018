<template>
    <div class="arrow-component">
      <div class="header">
        <img :src="arrowIcon" :alt="`Arrow ${data.direction}`" />
        <span class="percentage">{{ percentage }}%</span>
      </div>
      <span>по сравнению</span>
      <span>с предыдущим {{ timespanLabel }}</span>
    </div>
</template>
  
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    arrowIcon() {
      return this.data.direction === "up" 
        ? require('@/assets/icons/nav/Arrowup.ico') 
        : require('@/assets/icons/nav/Arrowdown.ico');
    },
    percentage() {
      return this.data.percentage;
    },
    timespanLabel() {
      switch (this.data.timespan) {
        case "month":
          return "месяцем";
        case "quarter":
          return "кварталом";
        case "year":
          return "годом";
        default:
          return "";
      }
    },
  },
};
</script>
  
<style scoped>
.arrow-component {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.arrow-component .header {
  display: flex;
  align-items: center;
}
.arrow-component img {
  margin-right: 5px;
  height: 45px;
  width: 20px;
}
.arrow-component .percentage {
  font-size: 52px; 
}
</style>
