document.title = "Dragonsoft";

import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import router from './router'
import axios from './axios'
import InfiniteLoading from 'vue-infinite-loading'
import ElementUI from 'element-ui'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import locale from 'element-ui/lib/locale/lang/ru-RU'
import Inputmask from 'inputmask'
import {store} from './store'
import './styles.scss'
import '@/svg';
import SvgIcon from '@/components/SvgIcon'
import ElTableDraggable from 'element-ui-el-table-draggable'
import moment from 'moment'
import 'moment/locale/ru'
import accounting from 'accounting'

accounting.settings = {
  currency: {
    symbol: 'р.',
    format: '%v %s',
    decimal: '.',
    thousand: ' ',
    precision: 2
  },
  number: {
    precision: '0',
    thousand: ' ',
    decimal: '.'
  }
}

moment.locale('ru')

Vue.directive('mask', {
  bind: function (el, binding) {
      Inputmask(binding.value).mask(el.getElementsByTagName('INPUT')[0])
  }
})

Vue.prototype.$axios = axios

Vue.use('el-table-draggable', ElTableDraggable)
Vue.component('svg-icon', SvgIcon);

Vue.use(InfiniteLoading, {})
Vue.use(ElementUI, { locale })
Vue.use(VueRouter)
Vue.use(VueMaterial)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
