<template>
  <div>
    <canvas id="chart"></canvas>
  </div>
</template>

<script>
import axios from 'axios'
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export default {
  props: ['userId'],
  data() {
    return {
      chart: null, // Ссылка на объект графика
    }
  },
  watch: {
    async userId(newVal, oldVal) {
      if (newVal !== oldVal) {
        await this.getData(); // Перезагрузка данных при изменении userId
      }
    }
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData() {
      let url = '/api/graph/graph/statistics/?timespan=quarter';
      if (this.userId) { // Используйте пропс userId
        url += `&user_id=${this.userId}`;
      }
      try {
        const response = await axios.get(url);
        const data = Object.entries(response.data).map(([month, stats]) => ({
          month,
          closed_percent: stats.closed_percent,
        }));

        // Подготовка данных для графика
        this.updateChart(data);
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
        if (this.chart) {
          this.chart.destroy(); // Удаление старого графика
        }
      }
    },

    updateChart(data) {
      const ctx = document.getElementById('chart').getContext('2d');
      if (this.chart) {
        this.chart.destroy(); // Удаление старого графика
      }
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: data.map(item => item.month),
          datasets: [{
            label: 'Процент закрытых задач',
            data: data.map(item => item.closed_percent),
            backgroundColor: data.map(item => this.getColor(item.closed_percent)),
            barThickness: 40, // Установка фиксированной ширины столбца в 40px
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              max: 100, // Максимальное значение для оси Y
              ticks: {
                // Добавление знака процента к каждой метке на оси Y
                callback: function (value) {
                  return `${value}%`;
                }
              }
            },
            x: {
              grid: {
                display: false // Отключение отображения сетки по оси X
              }
            }
          },
          plugins: {
            legend: {
              display: true, // Показывать легенду
            }
          },
          maintainAspectRatio: true // Позволяет графику изменять свои пропорции
        }
      });
    },
    getColor() {
      // Эта функция должна быть обновлена для работы с вашей логикой цветов
      // if (percent < 30) return '#FF6384';
      // if (percent >= 30 && percent < 70) return '#FFCD56';
      return '#36A2EB';
    }
  }
}
</script>

<style scoped></style>
