<template>
    <div class="company-settings">
        <div class="company-input">
            <md-field>
                <label>Телефон</label>
                <md-input v-model="editableCredentials.phone"></md-input>
            </md-field>

            <md-field>
                <label>Название компании</label>
                <md-input v-model="editableCredentials.company_name"></md-input>
            </md-field>

            <md-field>
                <label>ИНН</label>
                <md-input v-model="editableCredentials.inn"></md-input>
            </md-field>

            <md-field>
                <label>КПП</label>
                <md-input v-model="editableCredentials.kpp"></md-input>
            </md-field>

            <md-field>
                <label>Email</label>
                <md-input v-model="editableCredentials.email" type="email"></md-input>
            </md-field>
        </div>
        <div class="button-container">
            <md-button class="md-dense md-raised md-primary sub-but" @click="saveChanges">
                <span class="light-md-button">Сохранить</span>
            </md-button>
            <md-button class="md-dense md-primary button-set " @click="$emit('close')">
                <span class="light-md-button">Закрыть</span>
            </md-button>
        </div>
    </div>
</template>
  
<script>
import axios from 'axios';

export default {
    props: ['credentials'],
    data() {
        return {
            editableCredentials: { ...this.credentials }
        };
    },
    methods: {
        saveChanges() {
            axios.post('/api/users/credentials/', this.editableCredentials)
                .then(() => {
                    this.$emit('save', this.editableCredentials); // Отправляем обновленные данные обратно в родительский компонент
                })
                .catch(error => {
                    if (error.response && error.response.status === 400 && error.response.data) {
                        const errorMessages = error.response.data;
                        for (const field in errorMessages) {
                            if (Object.prototype.hasOwnProperty.call(errorMessages, field)) {
                                const messages = errorMessages[field];
                                for (const message of messages) {
                                    this.$notify.error({
                                        title: 'Ошибка',
                                        message: message
                                    });
                                }
                            }
                        }
                    } else {
                        console.error("Ошибка при сохранении данных:", error);
                    }
                });
        }
    }


};
</script>
  
<style>
.company-settings {
    max-width: 35vw;
}

.company-input {
    padding-left: 10px;
}
</style>