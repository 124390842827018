<template>
    <div>
        <el-form :model="model" :rules="rules" ref="form">
            <el-alert :title="errors.invite_token" type="error" v-if="errors.invite_token.length"></el-alert>
            <md-field :class="messageClass">
                <label>Email</label>
                <md-input v-model="model.email" :error="errors.email"></md-input>
            </md-field>
            <!-- <md-field :class="messageClass">
            <label>Скопируйте ключ с Bitrix</label>
            <md-input v-model="model.key" :error="errors.key"></md-input>
        </md-field> -->
            <md-field :class="messageClass">
                <label>Пароль</label>
                <md-input v-model="model.password" :error="errors.password" type="password"></md-input>
            </md-field>
            <md-field :class="messageClass">
                <label>Повторите пароль</label>
                <md-input v-model="model.passwordd" :error="errors.passwordd" type="password"></md-input>
            </md-field>
            <md-field :class="messageClass">
                <label>Имя</label>
                <md-input v-model="model.name" :error="errors.name"></md-input>
            </md-field>
            <md-field :class="messageClass">
                <label>Фамилия</label>
                <md-input v-model="model.surname" :error="errors.surname"></md-input>
            </md-field>
            <!-- <md-field :class="messageClass">
                <label>Телефон</label>
                <md-input v-model="model.phone" :error="errors.phone"></md-input>
            </md-field> -->
            <md-card-actions class="md-layout md-gutter" :class="`md-alignment-center`">
                <progress-spinner v-if="loading"></progress-spinner>
                <md-button class="md-raised md-primary auth-md-button" type="danger" native-type="submit"
                    @click.prevent="submitForm">Зарегистрироваться</md-button>
            </md-card-actions>
        </el-form>
    </div>
</template>

<script>

export default {
    name: "RegForm",
    data() {
        return {
            errors: {
                email: "",
                // key: "",
                password: "",
                passwordd: "",
                name: "",
                surname: "",
                // phone: "",
                invite_token: "",
            },
            model: {
                email: "",
                // key: "",
                name: "",
                password: "",
                passwordd: "",
                surname: "",
                // phone: "",
                invite_token: "",
            },
            loading: false,
            messageClass: "",
            rules: {
                email: [
                    {
                        required: true,
                        message: "Поле email обязательно",
                        trigger: "blur",
                    },
                    {
                        type: "email",
                        message: "Введен неправильный email",
                        trigger: "blur",
                    },
                ],
                // key: [
                //     {
                //         required: true,
                //         message: "Поле ключ обязательно",
                //         trigger: "blur",
                //     },
                //     {
                //         trigger: "blur",
                //     },
                // ],
                name: [
                    {
                        required: true,
                        message: "Имя обязательно",
                        trigger: "blur",
                    },
                    {
                        min: 2,
                        message: "Название компании должно содержать хотя бы 2 символа",
                        trigger: "blur",
                    },
                ],
                surname: [
                    {
                        required: true,
                        message: "Фамилия обязательно",
                        trigger: "blur",
                    },
                    {
                        min: 1,
                        message: "Фамилия должна содержать хотя бы 1 символ",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    mounted() {
        if (this.$route.query.inviteToken) {
            this.model.inviteToken = this.$route.query.inviteToken;
        }
    },
    methods: {
        async submitForm() {
            // Асинхронно проверяем валидность формы
            const valid = await this.$refs.form.validate();
            if (valid) {
                this.clearErrors(); // Очищаем предыдущие ошибки
                this.loading = true; // Показываем индикатор загрузки

                try {
                    // Отправляем данные на сервер
                    await this.$axios.post("/auth/reg/", {
                        email: this.model.email,
                        password: this.model.password,
                        second_password: this.model.passwordd,
                        first_name: this.model.name,
                        last_name: this.model.surname,
                        // phone: this.model.phone.replace(/\W/gi, ""),
                        invite_token: this.model.inviteToken,
                    });

                    // Обработка успешного ответа
                    this.$message({
                        message: "Вам на почту отправлено письмо с ссылкой подтверждения регистрации",
                        type: "success",
                    });
                    this.model = {}; // Очищаем модель формы
                } catch (error) {
                    if (error.response && error.response.data) {
                        const errors = error.response.data;
                        for (const key in errors) {
                            if (errors[key].length > 0) {
                                // Отображаем только первую ошибку
                                this.$message({
                                    message: errors[key][0],
                                    type: "error",
                                });
                                break; // Прерываем цикл после показа первой ошибки
                            }
                        }
                    } else {
                        // Обработка других типов ошибок
                        this.$message({
                            message: "Произошла ошибка. Попробуйте позже.",
                            type: "error",
                        });
                    }
                } finally {
                    this.loading = false; // Скрываем индикатор загрузки
                }
            }
        },

        clearErrors() {
            // Функция для очистки ошибок
            this.errors = {
                email: "",
                // key: "",
                password: "",
                passwordd: "",
                name: "",
                surname: "",
                // phone: "",
                invite_token: "",
            };
        }
    },

};
</script>
