<template>
  <div class="md-layout">
    <div v-for="(item, index) in blocks" :key="index" :style="{ backgroundColor: `#${item.color}` }"
      class="md-elevation-2 md-layout-item">
      <div class="column">
        <div v-html="item.text" class="md-subheading text"></div>
        <div class="md-headline count" v-if="item.count !== undefined">{{ item.count }}</div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  props: ['userId'],
  data() {
    return {
      blocks: [
        // { color: '00B4D8', text: 'Задач<br>всего', key: 'total' },
        { color: '40C3E3', text: 'Задач<br>выполняется', key: 'in_progress' },
        { color: '81D5EB', text: 'Задач<br>просрочено', key: 'overdue' },
        { color: '90E0EF', text: 'Задач<br>завершено', key: 'closed' },
        { color: 'CAF0F8', text: 'Задач завершено<br> с просрочкой', key: 'closed_overdue' },
      ],
    };
  },
  watch: {
    userId(newUserId) {
      this.fetchData(newUserId);
    }
  },
  methods: {
    fetchData(userId) {
      let url = '/api/statistics/statistics/tasks_totals/?timespan=month';
      if (userId) {
        url += `&user_id=${userId}`;
      }

      axios.get(url)
        .then(response => {
          // Обработка ответа
          const data = response.data;
          this.blocks.forEach((block, index) => {
            const count = data.tasks_total[block.key] || data[block.key] || 0;
            this.$set(this.blocks[index], 'count', count);
          });
        })
        .catch(error => {
          // Обработка ошибки
          console.log(error);
        });
    }
  },
  created() {
    this.fetchData(this.userId);
  },
  // created() {
  //   axios.get('/api/statistics/statistics/tasks_totals/?timespan=month')
  //     .then(response => {
  //       const data = response.data;
  //       this.blocks.forEach((block, index) => {
  //         const count = data.tasks_total[block.key] || data[block.key] || 0;
  //         this.$set(this.blocks[index], 'count', count);
  //       });
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // },
};
</script> 
  
<style scoped>
.md-layout {
  display: flex;
  /* justify-content: space-between; */
}

.md-layout-item {
  width: 220px;
  height: 130px;
  margin: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  position: relative;
  border-radius: 10px;
  box-sizing: border-box;
  max-width: 310px;
}

.column {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.text {
  text-align: right;
  font-size: 135%;
  padding-right: 10px;
}

@media (min-width: 1921px) {
  .text {
    font-size: 150%;
  }
}

.count {
  font-size: 500%;
}
</style>
  