<template>
    <div>
        <div class="main-title">
            <span class="md-headline">Статистика компании</span>
        </div>
        <div>
            <el-radio-group v-model="tabPosition" style="margin-left: 2vw;">
                <el-radio-button label="Месяц">Месяц</el-radio-button>
                <el-radio-button label="Квартал">Квартал</el-radio-button>
                <el-radio-button label="Год">Год</el-radio-button>
            </el-radio-group>
            <!-- <div class="grid-item">
                <employee-picker @select="handleEmployeeSelect" @employee-selected="handleEmployeeSelected"
                    @reset-data="resetDataD"></employee-picker>
                <employee-picker @select="handleEmployeeSelect" @reset-data="resetDataD"></employee-picker> 
            </div> -->
            <div>
                <tab-month v-if="tabPosition === 'Месяц'" :selected-employee="selectedEmployee"></tab-month>
                <tab-quater v-if="tabPosition === 'Квартал'" :selected-employee="selectedEmployee"></tab-quater>
                <tab-year v-if="tabPosition === 'Год'" :selected-employee="selectedEmployee"></tab-year>
            </div>
        </div>
    </div>
</template>

<script>
import TabMonth from './TabMonth.vue'
import TabQuater from './TabQuarter.vue'
import TabYear from './TabYear.vue'
// import EmployeePicker from './EmployeePicker.vue'


export default {
    // props: ['selectedEmployee'],
    components: {
        TabMonth,
        TabQuater,
        TabYear,
        // EmployeePicker
    },
    data() {
        return {
            tabPosition: 'Месяц',
            // showFilters: false,
            // selectedEmployee: null,
        };
    },
    methods: {
        changeTab(tab) {
            this.tabPosition = tab
        },
        // handleEmployeeSelect(employeeId) {
        //     this.selectedEmployee = { id: employeeId };
        //     this.$emit('employee-selected', employeeId); // Emitting an event
        // },
        // handleEmployeeSelected() {
        //     this.$refs.tabMonth.fetchData(); // Calling fetchData on the TabMonth component
        // },
    }
};
</script>

<style>
.main-title {
    margin-left: 2vw;
    padding-bottom: 30px;
}
</style>