<template>
  <div>
    <statistic-process-list></statistic-process-list>
    <el-radio-group v-model="tabPosition" style="margin-bottom: 30px;margin-left: 25px;">
      <el-radio-button label="Выполняются">Выполняются</el-radio-button>
      <el-radio-button label="Завершённые">Завершённые</el-radio-button>
      <el-radio-button label="Просроченные">Просроченные</el-radio-button>
    </el-radio-group>
    <md-button class="md-raised md-primary filt" @click="showFilters = !showFilters">Фильтры</md-button>
    <div v-if="showFilters" class="grid-container-filter">
      <div class="grid-item" style="padding-right: 2vw;">
        <date-range-picker :value="selectedDates" @input="updateSelectedDates"></date-range-picker>
      </div>
      <div class="grid-item" style="padding-right: 1vw;">
        <department-range-picker @select="handleDepartmentSelect" @reset-data="resetDataD"></department-range-picker>
      </div>
      <div class="grid-item">
        <employee-picker :selected-department="selectedDepartment" @select="handleEmployeeSelect"
          @reset-data="resetDataD"></employee-picker>
      </div>
    </div>
    <in-progress-process-list v-if="tabPosition === 'Выполняются'" :selected-dates="selectedDates"
      :selected-department="selectedDepartment" :selected-employee="selectedEmployee"
      @selectedGroup="onSelectedGroup"></in-progress-process-list>
    <completed-process-list v-if="tabPosition === 'Завершённые'" :selected-dates="selectedDates"
      :selected-department="selectedDepartment" :selected-employee="selectedEmployee" @selectedGroup="onSelectedGroup">
    </completed-process-list>
    <overdue-process-list v-if="tabPosition === 'Просроченные'" :selected-dates="selectedDates"
      :selected-department="selectedDepartment" :selected-employee="selectedEmployee" @selectedGroup="onSelectedGroup">
    </overdue-process-list>
  </div>
</template>

<script>
import InProgressProcessList from './InProgressProcessList.vue';
import CompletedProcessList from './CompletedProcessList.vue';
import OverdueProcessList from './OverdueProcessList.vue';
import StatisticProcessList from './StatisticProcessList.vue';
import DateRangePicker from "@/components/buildings/DateRangePicker.vue";
import DepartmentRangePicker from "@/components/buildings/DepartmentRangePicker.vue";
import EmployeePicker from '@/components/buildings/EmployeePicker.vue'

export default {
  components: {
    InProgressProcessList,
    CompletedProcessList,
    OverdueProcessList,
    StatisticProcessList,
    DateRangePicker,
    DepartmentRangePicker,
    EmployeePicker
  },
  data() {
    return {
      tabPosition: 'Выполняются',
      showFilters: false,
      isDataFetched: false,
      selectedDepartment: null,
      selectedDates: [],
      selectedEmployee: null,
    };
  },
  watch: {
    selectedDates: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          console.log('Selected dates in ProcessTabs:', newValue);
        }
      },
      deep: true,
    },
  },
  methods: {
    handleDateSelect(dateRange) {
      if (Array.isArray(dateRange) && dateRange.length === 2) {
        this.selectedDates = dateRange;
        console.log('Selected dates in ProcessTabs:', dateRange);
      } else {
        console.log('Invalid date range:', dateRange);
      }
    },
    updateSelectedDates(newSelectedDates) {
      this.selectedDates = newSelectedDates;
    },
    onSelectedGroup(data) {
      this.$emit("selectedGroup", data);
    },
    handleDepartmentSelect(departmentId) {
      this.selectedDepartment = departmentId;
    },
    handleEmployeeSelect(employeeId) {
      this.selectedEmployee = employeeId;
    },
  },
};
</script>

<style>
.filt {
  min-width: 88px;
  height: 36px;
  margin: 3px 8px !important;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none
}

.grid-container-filter {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-gap: 30px;
  margin-left: 45px;
}

.grid-item {
  /* Добавьте здесь стили по вашему усмотрению */
}
</style>