<template>
    <div class="profile-settings">
        <md-button class="md-dense md-primary button-set notUpper-md-button" @click="$emit('close')">Закрыть</md-button>
        <div class="prof-input">
            <md-field v-for="field in fields" :key="field.label">
                <label>{{ field.label }}</label>
                <md-input v-if="field.model === 'phone'" v-model="user[field.model]" @focus="handlePhoneFocus"
                    @input="validatePhone" :maxlength="12" placeholder="+7XXXXXXXXXX"></md-input>
                <md-input v-else v-model="user[field.model]"></md-input>
            </md-field>
            <md-field v-if="phoneChanged && !phoneVerified && codeSent">
                <label>Введите код подтверждения</label>
                <md-input v-model="pinCode"></md-input>
            </md-field>
        </div>
        <md-button v-if="phoneChanged && !phoneVerified" class="md-raised md-primary save-but notUpper-md-button" @click="handleClick">
            {{ codeSent ? 'Подтвердить код' : 'Выслать код' }}
        </md-button>
        <md-button v-if="phoneChanged && !phoneVerified" class="md-raised md-primary save-but notUpper-md-button"
            @click="cancelVerification">
            Отменить
        </md-button>
        <md-button class="md-raised md-primary save-but notUpper-md-button" @click="save"
            :disabled="phoneChanged && !phoneVerified">Сохранить</md-button>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: 'ProfileSettings',
    props: ['user'],
    data() {
        return {
            initialUser: null,
            phoneChanged: false,
            phoneVerified: false,
            codeSent: false,
            pinCode: '',
            fields: [
                { label: 'Логин', model: 'email' },
                { label: 'Ключ', model: 'webhook' },
                { label: 'Токен приложения "Битрикс 24"', model: 'dragon_token' },
                { label: 'Имя', model: 'first_name' },
                { label: 'Фамилия', model: 'last_name' },
                { label: 'Телефон', model: 'phone' }
            ]
        }
    },
    watch: {
        'user.phone': function (newVal, oldVal) {
            if (this.isSignificantChange(newVal, oldVal)) {
                this.phoneChanged = true;
                this.codeSent = false; // Reset при изменении номера
                this.phoneVerified = false;
            }
        }
    },
    mounted() {
        // Копируем исходные данные пользователя
        this.initialUser = { ...this.user };
        if (!this.user.phone) {
            this.user.phone = '+7'; // Инициализируется с '+7', если номер пустой
        }
    },
    methods: {
        handleClick() {
            if (this.codeSent) {
                this.confirmPin();
            } else {
                this.verifyPhone();
            }
        },
        save() {
            // Собираем измененные данные, исключая "+"
            let changedData = {};
            for (let key in this.user) {
                let value = this.user[key];
                if (this.user[key] !== this.initialUser[key]) {
                    if (key === 'phone') {
                        // Убираем "+" из номера перед отправкой
                        value = value.replace(/^\+/, '');
                    }
                    changedData[key] = value;
                }
            }

            // Отправка измененных данных на сервер
            if (Object.keys(changedData).length === 0) {
                alert("Нет изменений для сохранения!");
                return;
            }

            axios.post('/api/users/user/change/', changedData)
                .then(() => {
                    alert("Данные успешно изменены!");
                    this.$emit('close');
                })
                .catch(error => {
                    console.error(error);
                    alert("Произошла ошибка при сохранении изменений!");
                });
        },
        verifyPhone() {
            console.log("Вызов verifyPhone");

            axios.post('/api/auth/phone/', { phone: this.user.phone, dictate: true })
                .then(() => {
                    this.codeSent = true;
                    alert("Код подтверждения отправлен на ваш номер.");
                })
                .catch(() => {
                    alert("Ошибка при отправке кода.");
                });
        },
        validatePhone(event) {
            const phoneInput = event.target.value;
            const cleanInput = phoneInput.replace(/[^\d+]/g, '');

            if (cleanInput.match(/^\+7\d{0,10}$/)) {
                this.user.phone = cleanInput;
            } else if (cleanInput.length > 1) {
                event.target.value = this.user.phone;
            }
        },
        confirmPin() {
            console.log("Вызов confirmPin");

            axios.post('/api/auth/phone/check_pin/', { pin_code: this.pinCode })
                .then(() => {
                    this.phoneVerified = true;
                    this.phoneChanged = false; // После подтверждения считаем телефон неизмененным
                    alert("Телефон успешно подтвержден.");
                })
                .catch(error => {
                    let errorMessage = "Неверный пин-код или произошла ошибка при подтверждении.";
                    if (error.response && error.response.data) {
                        const errors = error.response.data;
                        if (errors.pin_code) {
                            errorMessage = "Неверный пин-код. Попробуйте снова.";
                        } else if (errors.message) {
                            errorMessage = errors.message;
                        }
                    }
                    alert(errorMessage);
                });
        },
        handlePhoneFocus() {
            if (!this.user.phone || this.user.phone === '+7') {
                this.user.phone = '+7'; // Установите '+7', когда пользователь фокусируется на поле
            }
        },
        isSignificantChange(newVal, oldVal) {
            // Удаление всех нецифровых символов перед сравнением
            const cleanNewVal = newVal.replace(/[^\d]/g, '');
            const cleanOldVal = oldVal.replace(/[^\d]/g, '');
            return cleanNewVal !== cleanOldVal;
        },
        cancelVerification() {
            // Проверка, был ли номер телефона изменен до нажатия "Отменить"
            if (this.phoneChanged) {
                this.user.phone = this.initialUser.phone; // Восстановление исходного номера
                if (!this.user.phone) {
                    this.user.phone = '+7'; // Автозаполнение, если номер был пустым
                }
            } else {
                // Если изменений не было, просто убеждаемся, что номер телефона корректен
                this.user.phone = this.user.phone || '+7';
            }

            // Сброс состояний
            this.phoneChanged = false;
            this.phoneVerified = true; // Если требуется
            this.codeSent = false;
            this.pinCode = '';
        }
    },
};
</script>


<style>
.profile-settings {
    max-width: 35vw;
}

.prof-input {
    padding-left: 10px;
}


</style>