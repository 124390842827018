<template>
  <svg :class="className" aria-hidden="true" :style="style">
    <title v-if="title">{{ title }}</title>
    <use :xlink:href="iconPath" />
  </svg>
</template>

<script>
export default {
  name: 'svg-icon',

  props: {
    name: {
      type: String,
      required: true
    },

    title: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: null
    },
    width: {
      type: String,
      default: null
    },
    center: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    },
    top: {
      type: Boolean,
      default: false
    },
    baseline: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    style() {
      let styles = [];
      if (this.height) {
        styles.push('height: ' + this.height + ';')
      }
      if (this.width) {
        styles.push('width: ' + this.width + ';')
      }
      return styles.join(';')
    },
    iconPath() {
      let icon = require(`@/assets/icons/${this.name}.svg`);
      if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        icon = icon.default;
      }

      return icon.url;
    },

    className() {
      let classes = ['svg-icon svg-icon--' + this.name, 'inline-block'];
      if (this.center) {
        classes.push('align-middle')
      }
      if (this.bottom) {
        classes.push('align-bottom')
      }
      if (this.top) {
        classes.push('align-top')
      }
      if (this.baseline) {
        classes.push('align-baseline')
      }
      return classes.join(' ');
    }
  }
};
</script>

<style>
  .svg-icon {
    fill: currentColor;
    height: 24px;
    width: 24px;
  }
</style>
