<template>
  <canvas id="chart"></canvas>
</template>

<script>
import axios from 'axios';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
  name: 'ChartComponent',
  props: ['userId'],
  data() {
    return {
      chart: null, // Ссылка на экземпляр графика
    };
  },
  watch: {
    async userId(newUserId) {
      await this.fetchData(newUserId);
    }
  },
  mounted() {
    this.fetchData(this.userId);
  },
  methods: {
    async fetchData(userId) {
      console.log("fetchData called with userId:", userId);
      let url = '/api/graph/graph/statistics/?timespan=year';
      if (userId) {
        url += `&user_id=${userId}`;
      }

      try {
        const response = await axios.get(url);
        const data = response.data; // Используем пример данных, предоставленный вами

        const labels = Object.keys(data);
        const datasetData = labels.map(label => data[label].closed_percent);

        const chartData = {
          labels: labels,
          datasets: [{
            label: 'Процент закрытия',
            data: datasetData,
            fill: true, // Заливка под линией
            backgroundColor: 'rgba(202, 240, 248, 0.5)', // Цвет заливки
            borderColor: '#CAF0F8',
            borderWidth: 1.5,
            tension: 0.4,
            pointRadius: 5, // Увеличенный радиус точек
            pointBackgroundColor: '#CAF0F8', // Цвет точек
          }]
        };

        if (this.chart) {
          this.chart.data = chartData;
          this.chart.update();
        } else {
          this.drawChart(chartData);
        }
      } catch (error) {
        console.error(error);
      }
    },
    drawChart(chartData) {
      const ctx = document.getElementById('chart').getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'line',
        data: chartData,
        options: {
          scales: {
            y: {
              beginAtZero: true,
              max: 100, // Установка максимального значения оси Y
              ticks: {
                // Добавление знака процента к каждой метке на оси Y
                callback: function (value) {
                  return `${value}%`;
                }
              }
            }
          }
        }
      });
    }
  }
};
</script>

<style scoped></style>
