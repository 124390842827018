<template>
    <div>
        <h2 class="md-headline" style=" padding-left: 20px;">Структура организации</h2>
        <div v-if="!isChildActive">
            <div class="md-layout">
                <md-card class="md-elevation-2 md-layout-item user-card" style="background-color: #CAF0F8;">
                    <md-card-header>
                        <md-card-header-text class="md-list-item-text">
                            <span class="md-title">{{ user.first_name }} {{ user.last_name }}</span>
                            <span class="md-caption">{{ user.position_verbose }}</span>
                        </md-card-header-text>
                    </md-card-header>
                </md-card>
            </div>
            <div class="md-layout-v">
                <md-content v-for="(block, index) in organizationChildren" :key="index"
                    class="md-elevation-2 md-layout-item organization-block" @click="showChildComponent(block)">
                    <div class="md-list-item-text">
                        <span class="md-title">{{ block.name }}</span>
                    </div>
                </md-content>
            </div>
        </div>

        <organization-details v-if="isChildActive && selectedBlock" :key="selectedBlock.id" :details="selectedBlock"
            :departmentId="selectedBlock.id" :isActive="selectedBlock !== null"
            @close="isChildActive = false; selectedBlock = null">
        </organization-details>
    </div>
</template>

<script>
import axios from 'axios';
import OrganizationDetails from './OrganizationDetails.vue';

export default {
    name: 'OrganizationStructure',
    components: {
        OrganizationDetails,
    },
    data() {
        return {
            user: {
                first_name: '',
                last_name: '',
                position_verbose: '',
            },
            organizationChildren: [],
            selectedBlock: null,
            isChildActive: false,
        };
    },
    mounted() {
        this.fetchUserData();
        this.fetchOrganizationStructure();
    },
    methods: {
        async fetchUserData() {
            try {
                const response = await axios.get('/api/users/user/me/');
                this.user = response.data;
            } catch (error) {
                console.error('Ошибка при получении данных пользователя:', error);
            }
        },
        async fetchOrganizationStructure() {
            try {
                const response = await axios.get('/api/organization/structure/');
                const children = response.data[0].children;
                this.organizationChildren = children.map(children => ({
                    id: children.id,
                    name: children.name,
                    head_id: children.head_id,
                    childrenCount: children.length
                }));
            } catch (error) {
                console.error('Ошибка при получении структуры организации:', error);
            }
        },
        showChildComponent(block) {
            this.selectedBlock = block;
            this.isChildActive = true;
        }

    },
};
</script>


<style scoped>
.md-layout {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    padding-left: 3vh;
    padding-bottom: 2vh;
}

.md-layout-v {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    min-height: 150px;
    padding-left: 3vh;
}

.md-layout-item {
    flex: 1;
    max-width: 300px;
    margin: 8px;
    text-align: center;
    border-radius: 10px;
}


.md-title {
    font-size: 1.2rem;
    display: block;
}

.organization-block .md-list-item-text {
    display: flex;
    justify-content: center;
    align-items: center;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.organization-block {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 150px;
    margin: 24px;
    flex-direction: column;
    padding: 10px;
    position: relative;
    border-radius: 10px;
    min-width: 300px !important;
    max-width: 300px;
    box-sizing: border-box;
    padding: 16px;
}
</style>