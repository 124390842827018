<template>
  <md-layout md-tag="div"  class="auth-wrap">
    <!-- Блок с изображением -->
    <div class="auth-image">
      <img src="@/assets/imgs/Dragon-3.svg">
    </div>

    <md-layout md-tag="div"  class="auth-inner">
      <slot></slot>
    </md-layout>
  </md-layout>
</template>

<script>
export default {
  name: "Layout",
};
</script>

<style lang="scss" scoped>
.auth-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;  // Занимает всю высоту экрана
  width: 100vw;   // Занимает всю ширину экрана
  background: #ffffff;
  overflow: hidden;  // Отключает скролл
}

.auth-image {
  display: flex;
  align-items: center;
  justify-content: center;
  //flex: 1;  // Занимает доступное пространство, но не более половины экрана

  img {
    max-width: 100%;
  }
  @media (max-width: 800px) {
    display: none; // Скрывает блок изображения на экранах шириной меньше 800px
  }
}

.auth-inner {
  flex: 1;  // Занимает доступное пространство, но не более половины экрана
  max-width: 500px;
  padding: 20px;
  background: white;
}
</style>
