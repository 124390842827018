<template>
    <div>
        <canvas ref="canvas"></canvas>
        <md-button class="md-icon-button" @click="zoomOut">
            <md-icon>remove</md-icon>
        </md-button>
        <md-button class="md-icon-button" @click="zoomIn">
            <md-icon>add</md-icon>
        </md-button>

    </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import axios from 'axios';
import zoomPlugin from 'chartjs-plugin-zoom';

Chart.register(...registerables, zoomPlugin);

export default {
    props: {
        groupId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            chart: null,
            graphData: null, // Сюда будут загружены данные
        };
    },
    async mounted() {
        await this.fetchGraphData();
        this.createChart();
    },
    methods: {
        async fetchGraphData() {
            try {
                const response = await axios.get(`/api/graph/graph/process_performance/?process_id=${this.groupId}`);
                this.graphData = response.data;
                // Теперь у нас есть данные, можно их использовать для создания графика
            } catch (error) {
                console.error("Failed to fetch graph data:", error);
            }
        },
        zoomIn() {
            this.chart.zoom(1.1); // Увеличиваем масштаб на 10%
        },
        zoomOut() {
            this.chart.zoom(0.9); // Уменьшаем масштаб на 10%
        },
        createChart() {
            const ctx = this.$refs.canvas.getContext('2d');
            this.chart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: this.prepareLabels(this.graphData.graph.plan), // Готовим метки для оси X
                    datasets: [
                        {
                            label: 'План',
                            data: this.graphData.graph.plan.map(item => item[2]),
                            borderColor: '#CAF0F8',
                            backgroundColor: 'rgba(202, 240, 248, 0.8)',
                            pointStyle: 'circle', // Устанавливаем стиль точек как круглые
                            pointRadius: 7,
                            pointHoverRadius: 12
                        },
                        {
                            label: 'Факт',
                            data: this.prepareDataset(this.graphData.graph.real, this.graphData.graph.plan),
                            borderColor: '#00B4D8',
                            backgroundColor: 'rgba(0, 180, 216)',
                            pointStyle: 'circle',
                            pointRadius: 7,
                            pointHoverRadius: 12
                        },
                        {
                            label: 'Прогноз',
                            data: this.prepareDataset(this.graphData.graph.predicted, this.graphData.graph.plan),
                            borderColor: '#98ff98',
                            backgroundColor: 'rgba(152, 255, 152)',
                            pointStyle: 'circle',
                            pointRadius: 7,
                            pointHoverRadius: 12
                        }
                    ]
                },
                options: {
                    interaction: {
                        mode: 'index',
                        intersect: false,
                    },
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    },
                    parsing: {
                        xAxisKey: 'x',
                        yAxisKey: 'y'
                    },
                    plugins: {
                        zoom: {
                            pan: {
                                enabled: true,
                                mode: 'x',
                                speed: 20, // Уменьшить для более плавного панорамирования
                                threshold: 10, // Увеличь порог для начала панорамирования
                            },
                            limits: {
                                x: { min: 'original', max: 'original', minRange: 1 },
                            },
                            zoom: {
                                wheel: {
                                    enabled: true,
                                    speed: 0.01, // Уменьшить для более плавного масштабирования колесом мыши
                                },
                                pinch: {
                                    enabled: true,
                                    speed: 0.01, // Уменьшить для более плавного масштабирования щипком
                                },
                                mode: 'x',
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: function (context) {
                                    // Получаем значение и метку из контекста
                                    var label = context.dataset.label || '';
                                    var value = context.parsed.y;
                                    // Добавляем к значению знак %
                                    return `${label}: ${value} %`;
                                },
                                afterBody: (context) => {
                                    // Доступ к данным графика через context
                                    let tooltipIndex = context[0].dataIndex;
                                    // Массив данных для 'plan', 'real', или 'predicted', в зависимости от того, что выбрано в tooltip
                                    let datasetLabel = context[0].dataset.label;
                                    let taskName = ''; // Переменная для хранения названия задачи

                                    // Определение, из какого массива брать данные
                                    if (datasetLabel === 'План') {
                                        taskName = this.graphData.graph.plan[tooltipIndex][3] || 'Нет названия задачи';
                                    } else if (datasetLabel === 'Факт') {
                                        let planIndex = this.graphData.graph.plan.findIndex(item => item[0] === this.graphData.graph.real[tooltipIndex][0]);
                                        taskName = planIndex !== -1 ? this.graphData.graph.plan[planIndex][3] : 'Нет названия задачи';
                                    } else if (datasetLabel === 'Прогноз') {
                                        let planIndex = this.graphData.graph.plan.findIndex(item => item[0] === this.graphData.graph.predicted[tooltipIndex][0]);
                                        taskName = planIndex !== -1 ? this.graphData.graph.plan[planIndex][3] : 'Нет названия задачи';
                                    }

                                    // Возвращаем название задачи для отображения в подсказке
                                    return taskName;
                                }
                            }
                        }
                    }
                }
            });
        },
        prepareLabels(data) {
            return data.map(item => {
                const isoDate = item[1].split('T')[0]; // Извлекаем YYYY-MM-DD 
                const [year, month, day] = isoDate.split('-'); // возвращаем строку в новом формате
                return `${day}-${month}-${year}`; // Отправляем как DD MM YYYY
            });
        },
        prepareDataset(dataset, reference) {
            let result = new Array(reference.length).fill(null); // Инициализируем массив нулями
            dataset.forEach((item) => {
                const index = reference.findIndex(refItem => refItem[0] === item[0]);
                if (index !== -1) result[index] = item[2];
            });
            return result;
        },
        //При этом коде график будет рисоваться всегда, но даты будут привязанны к дургой велечине
        // prepareDataset(dataset, reference) {
        //     let result = [];
        //     let lastValidIndex = -1;

        //     reference.forEach((refItem, index) => {
        //         const itemIndex = dataset.findIndex(item => item[0] === refItem[0]);

        //         if (itemIndex !== -1) {
        //             // Если находим соответствующий элемент, сохраняем его значение
        //             result.push(dataset[itemIndex][2]);
        //             lastValidIndex = index; // Обновляем последний валидный индекс
        //         } else if (lastValidIndex !== -1 && index !== reference.length - 1) {
        //             // Если это не последний элемент, и предыдущий индекс был валидным,
        //             // добавляем null, чтобы сохранить пропуски между точками
        //             result.push(null);
        //         }
        //     });

        //     // Фильтруем результат, убирая начальные и конечные null, чтобы соединять только реальные точки
        //     return result.filter((item, index, array) => {
        //         // Убираем null, если он не находится между двумя валидными значениями
        //         return item !== null || (index > 0 && array[index - 1] !== null && index < array.length - 1 && array[index + 1] !== null);
        //     });
        // },

        updateChartData(newData) {
            // Функция для обновления данных графика
            this.chart.data.labels = newData.labels;
            this.chart.data.datasets.forEach((dataset) => {
                dataset.data = newData.data;
            });
            this.chart.update();
        }
    },
    watch: {
        groupId(newValue, oldValue) {
            if (newValue !== oldValue) {
                // Здесь можно добавить логику для загрузки новых данных при изменении groupId
            }
        }
    }
};
</script>

<style scoped></style>