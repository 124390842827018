<template>
    <div class="subscription-container">
        <div class="grid-container-pay">
            <div class="grid-item"><span class="static-line">Подписка</span></div>
            <div class="grid-item">{{ subscription.name }}</div>

            <div class="grid-item"><span class="static-line">Сведения</span></div>
            <div class="grid-item">{{ subscription.description }}</div>

            <div class="grid-item"><span class="static-line">Период действия</span></div>
            <div class="grid-item">до {{ subscription.period }}</div>

            <div class="grid-item"><span class="static-line">Цена</span></div>
            <div class="grid-item">{{ subscription.price }} ₽</div>

            <md-field class="grid-item">
                <label>Промокод</label>
                <md-input v-model="promocode"></md-input>
            </md-field>
        </div>

        <div v-if="!paymentStarted">
            <md-button class="md-dense md-raised md-primary sub-but " @click="startPayment">
                <span class="light-md-button">Оформить подписку</span>
            </md-button>
            <md-button class="md-dense md-primary button-set" @click="$emit('back')"><span
                class="light-md-button">Назад</span></md-button>
        </div>
        <div v-else>
            <md-button class="md-dense md-primary button-set" @click="payByDetails">
                Оплатить по реквизитам
            </md-button>
            <!-- <md-button class="md-dense md-primary button-set" @click="payOnline">
                Оплатить онлайн
            </md-button> -->
            <md-button class="md-dense md-primary button-set" @click="goBackToSelection">
                <span class="light-md-button">Отмена</span>
            </md-button>
        </div>
        <md-dialog :md-active.sync="showDialog">
            <md-dialog-title>Введите реквизиты</md-dialog-title>
            <md-dialog-content>
                <div class="company-settings">
                    <div class="company-input">
                        <md-field>
                            <label>Телефон</label>
                            <md-input v-model="editableCredentials.phone"></md-input>
                        </md-field>
                        <md-field>
                            <label>Название компании</label>
                            <md-input v-model="editableCredentials.company_name"></md-input>
                        </md-field>

                        <md-field>
                            <label>ИНН</label>
                            <md-input v-model="editableCredentials.inn"></md-input>
                        </md-field>

                        <md-field>
                            <label>КПП</label>
                            <md-input v-model="editableCredentials.kpp"></md-input>
                        </md-field>

                        <md-field>
                            <label>Email</label>
                            <md-input v-model="editableCredentials.email" type="email"></md-input>
                        </md-field>
                    </div>
                </div>
                <!-- <ErrorModal @close="showDialog = false" @save="handleSave" /> -->
            </md-dialog-content>
            <md-dialog-actions>
                <md-button class="md-dense md-raised md-primary sub-but" @click="saveChanges">
                    <span class="light-md-button">Сохранить</span>
                </md-button>
                <md-button class="md-dense md-primary button-set" @click="showDialog = false">
                    <span class="light-md-button">Закрыть</span>
                </md-button>
                <!-- <md-button class="md-primary" @click="showDialog = false">Закрыть</md-button> -->
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>
  
<script>
import axios from 'axios';
// import ErrorModal from './ErrorModal.vue';


export default {
    // components: {
    //     ErrorModal
    // },
    props: {
        subscription: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            promocode: '',
            paymentStarted: false,
            showDialog: false,
            editableCredentials: { ...this.credentials }
        };
    },
    mounted() {
        console.log("Props:", this.subscription);
    },
    methods: {
        startPayment() {
            this.paymentStarted = true;
        },
        goBackToSelection() {
            this.paymentStarted = false;
        },
        payByDetails() {
            const data = {
                provider: 1,
                subscription_id: this.subscription.id
            };

            if (this.promocode.trim() !== "") {
                data.promocode = this.promocode;
            }

            axios.post('/api/subscription/payment/new_payment/', data)
                .then(response => {
                    const url = response.data.url;
                    window.open(url, '_blank');
                })
                .catch(error => {
                    if (error.response && error.response.status === 404) {
                        // Показать модальное окно с сообщением об ошибке
                        this.showDialog = true;
                    } else {
                        console.error("Ошибка при оплате по реквизитам:", error)
                    }
                })
        },
        saveChanges() {
            axios.post('/api/users/credentials/', this.editableCredentials)
                .then(() => {
                    this.$emit('save', this.editableCredentials); 
                    this.showDialog = false;
                })
                .catch(error => {
                    console.error("Ошибка при сохранении данных:", error);
                });
        },
        // handleSave() {
        //     // Обработка обновленных данных от дочернего компонента
        //     // Закрыть md-dialog
        //     this.showDialog = false;
        // },
       
        // payOnline() {
        //     axios.post('/subscription/payment/new_payment/')
        //         .then(response => {
        //             const url = response.data.url;
        //             window.location.href = url;
        //         })
        //         .catch(error => {
        //             console.error("Ошибка при онлайн оплате:", error);
        //         });
        // },
        applyPromocode() {
            // Здесь код для отправки промокода на сервер
            // axios.post('/your-url-for-promocode', { code: this.promocode })
        }
    }
}
</script>

<style>
.subscription-container {
    max-width: 35vw;
}

.grid-container-pay {
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
    row-gap: 2rem;
    padding-left: 10px;
    max-width: 45vw;
    padding-top: 4vh;
    padding-bottom: 4vh;
}

.sub-but {
    border-radius: 5px;
    text-transform: none;
}

.company-settings {
    max-width: 35vw;
}

.company-input {
    padding-left: 10px;
}


</style>
  