<template>
    <div>
        <md-field>
            <label>Имя</label>
            <md-input v-model="userData.first_name"></md-input>
        </md-field>
        <md-field>
            <label>Фамилия</label>
            <md-input v-model="userData.last_name"></md-input>
        </md-field>
        <md-field>
            <label>Email</label>
            <md-input v-model="userData.email"></md-input>
        </md-field>
        <md-field>
            <label>Телефон</label>
            <md-input v-model="userData.phone"></md-input>
        </md-field>
        <md-field>
            <label for="departments">Отдел</label>
            <md-select v-model="selectedDepartments" name="departments" id="departments" multiple>
                <md-option v-for="department in allDepartments" :key="department.id" :value="department.id">{{
                    department.name }}</md-option>
            </md-select>
        </md-field>
        <md-button class="md-dense md-primary button-add notUpper-md-button" @click="saveChanges">Сохранить</md-button>
        <md-button class="md-dense md-accent button-delete notUpper-md-button" @click="deleteUser">Удалить</md-button>
        <md-button class="md-dense md-primary button-set notUpper-md-button" @click="$emit('close')">Закрыть</md-button>
    </div>
</template>
  
<script>
import axios from 'axios';

export default {
    props: {
        userData: {
            type: Object,
            default: () => ({})
        },
        userId: {
            type: [Number, String],
            required: true
        }
    },
    data() {
        return {
            allDepartments: [],
            userCopy: {},  // Локальная копия данных пользователя
            selectedDepartments: [],
        };
    },
    mounted() {
        this.fetchAllDepartments();
        this.fetchUserData();
    },
    watch: {
        userData: {
            handler(newValue) {
                this.userCopy = { ...newValue };  // Обновите локальную копию, когда props userData меняется
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        async fetchAllDepartments() {
            try {
                const response = await axios.get('/api/organization/structure/as_list/');
                this.allDepartments = response.data;
            } catch (error) {
                console.error('Error fetching departments:', error);
            }
        },
        async fetchUserData() {
            try {
                const response = await axios.get(`/api/users/ceo_employees/${this.userId}/`);
                this.userCopy = { ...response.data };  // Используйте userCopy вместо userData

                // Set the selected departments
                this.selectedDepartments = this.userCopy.departments.map(dept => dept.id);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        },
        async saveChanges() {
            if (!this.userCopy.id) {
                console.error('No user ID provided for updating');
                return;
            }

            try {
                const response = await axios.patch(`/api/users/ceo_employees/${this.userCopy.id}/`, this.userCopy);
                console.log('Update successful:', response.data);
                this.$emit('close'); // Close the form after successful save
            } catch (error) {
                console.error('Error updating user:', error);
            }
        },
        async deleteUser() {
            if (!this.userId) {
                console.error('No user ID provided for deletion');
                return;
            }

            try {
                const response = await axios.delete(`/api/users/ceo_employees/${this.userId}/`);
                console.log('Delete successful:', response.data);
                this.$emit('user-deleted', this.userId); // Оповестите родительский компонент об удалении пользователя
                this.$emit('close'); // Закройте форму после успешного удаления
            } catch (error) {
                console.error('Error deleting user:', error);
            }
        }
    }
}
</script>
