<template>
    <div>
        <div v-if="!selectedSubscription">
            <md-button class="md-dense md-primary button-set" @click="$emit('back')"><span
                class="light-md-button">Закрыть</span></md-button>
            <div class="subscriptions-container">
                <div v-for="item in subscriptions" :key="item.id" class="subscription-block"
                    @click="selectSubscription(item.id)">
                    <div class="subscription-name">{{ item.name }}</div>
                    <div class="subscription-description">{{ item.description }}</div>
                    <div class="subscription-price">{{ item.price }} руб.</div>
                </div>
            </div>
        </div>
        <SubscriptionDetail v-if="selectedSubscription" :subscription="selectedSubscription"
            @back="selectedSubscription = null" />
    </div>
</template>


<script>
import axios from 'axios';
import SubscriptionDetail from './SubscriptionDetail.vue';

export default {
    components: {
        SubscriptionDetail
    },
    data() {
        return {
            subscriptions: [],
            selectedSubscription: null
        };
    },
    methods: {
        // selectSubscription(item) {
        //     this.$emit('selected', item);
        //     this.selectedSubscription = item;
        // },
        selectSubscription(id) {
            // Находим нужную подписку из списка по ID
            const selected = this.subscriptions.find(sub => sub.id === id);
            if (selected) {
                this.selectedSubscription = selected;
            }
        },
        getAvailableSubscriptions() {
            axios.get('/api/subscription/subscription/')
                .then(response => {
                    this.subscriptions = response.data;
                })
                .catch(error => {
                    console.error(error);
                    // Обрабатываем ошибку. Например, отправляем событие об ошибке наверх.
                    this.$emit('error', 'Ошибка при получении списка подписок');
                });
        },
        back() {
            this.$emit('back');
        }
    },
    created() {
        this.getAvailableSubscriptions();
    }
};
</script>

<style>
.subscriptions-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* разрешает перенос элементов на следующую строку, если они не влезают в родительский контейнер */
    gap: 10px;
    /* устанавливает промежутки между элементами */
}

.subscription-block {
    width: 400px;
    height: 191px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.05);
    margin: 10px;
    /* дополнительный отступ между блоками */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* центрируем содержимое по вертикали */
    align-items: center;
    /* центрируем содержимое по горизонтали */
}

.subscription-name {
    color: #27282B;
    text-align: center;
    font-family: 'Gilroy';
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 5px;
    /* расстояние между элементами */
}

.subscription-description {
    color: #27282B;
    text-align: center;
    font-family: 'Gilroy';
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 5px;
    /* расстояние между элементами */
}

.subscription-price {
    color: #0077B6;
    text-align: center;
    font-family: 'Gilroy';
    font-size: 16px;
    font-weight: 500;
    line-height: 130%;
}
</style>
