<template>
            <AuthForm />
</template>

<script>
    import AuthForm from '@/components/auth/Auth.vue'
    export default {
        name: 'AuthPage',
        components: {
            AuthForm
        },

        data() {
            return {
                verifyLoading: false
            }
        },

        mounted() {
            if (this.$route.query.uid) {
                this.verifyUser(this.$route.query)
            }
        },

        methods: {
            verifyUser(query) {
                const loading = this.$loading({
                    lock: true,
                    text: 'Секундочку',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })
                const {
                    uid,
                    token
                } = query

                this.$axios.post('/auth/reg/verify_email/', {
                        'uid': uid,
                        'token': token
                    })
                    .then(() => {
                        this.$message({
                            'message': 'Вы подтвердили ваш email. Теперь вы можете войти',
                            'type': 'success'
                        })
                        this.$router.push({
                            'name': 'signin'
                        })
                    })
                    .catch(({
                        response
                    }) => {
                        if (response.status == 404) {
                            this.$message({
                                'message': 'Пользователь не найден. Обратитесь к администратору',
                                'type': 'error'
                            })
                        } else {
                            this.$message({
                                'message': 'Внутренняя ошибка сервера. Повторите попытку позже',
                                'type': 'error'
                            })
                        }
                    })
                    .then(() => {
                        loading.close();
                    })
            }
        }
    }
</script>
