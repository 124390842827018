<template>
    <Organizationtab/>
  </template>
  
  <script>
  import Organizationtab from '@/components/organization/OrganizationStructure.vue';
  
  export default {
    name: 'OrganizationPage',
    components: {
      Organizationtab,
    },
  
    data() {
      return {};
    },
  
    mounted() {},
  
    methods: {},
  };
  </script>
  