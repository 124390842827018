import Vue from 'vue'
import VueRouter from 'vue-router'

import AuthPage from './pages/Auth.vue'
import SettingsPage from './pages/Settings.vue'
import BusinessProcess from './pages/BusinessProcess.vue'
import OrganizationPage from './pages/OrganizationStructure.vue';
import TaskList from './components/Task/TaskList.vue';
import Chart from './components/graph/Chart.vue';
import StatisticPage from './components/statistic/Statistic.vue'

import {
    store
} from './store'
import axios from './axios'
import _ from 'lodash'
import {
    Loading
} from 'element-ui'

Vue.use(VueRouter)

const routes = [{
        path: '/auth',
        name: 'auth',
        component: AuthPage
    },

    {
        path: '/settings/',
        name: 'settings',
        component: SettingsPage
    },
    {
        path: '/',
        name: 'business',
        component: BusinessProcess
    },
    {
        path: '/organization/',
        name: 'organization',
        component: OrganizationPage,
    },
    {
        path: '/statistic/',
        name: 'statistic',
        component: StatisticPage,
    },
    {
        path: '/task-list/:groupId',
        name: 'task-list',
        component: TaskList,
        props: true,
    },
    {
        path: '/chart/:groupId',
        name: 'chart',
        component: Chart,
        props: true
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

async function getMe() {
    let axiosGet = axios.get
    let response = await axiosGet('/users/user/me/')
        .then(({
            data
        }) => {
            return data
        })
        .catch(() => {
            return {}
        })

    return response
}


router.beforeEach(async (to, from, next) => {

    let loading = Loading.service({
        fullscreen: true
    })

    let me = await getMe()
    let isEmpty = _.isEmpty(me)

    console.log(to.path)
    if (to.path !== '/auth/' && to.path !== '/auth') {
        if (!isEmpty) {
            await store.dispatch('me/set', me)
        } else {
            await store.dispatch('me/clear')
            localStorage.setItem('nextPageAfterAuth', to.fullPath)
            next({
                path: '/auth'
            })
        }
    } else if (!isEmpty) {
        next({
            path: '/'
        })
    }

    loading.close()

    next()
})

export default router