<template>
  <div class="payment-history-show">
    <md-button class="md-dense md-primary button-set notUpper-md-button" @click="$emit('close')"> <span class="light-md-button">Закрыть</span></md-button>
    <md-button  class="md-dense md-primary button-continue light-md-button"><span class="light-md-button">Продолжить оплату</span></md-button>
    <md-table md-sort="name" v-model="payments" md-fixed-header style="background-color: #FBFBFB;">
      <md-table-row slot="md-table-row" slot-scope="{ item }" :class="{ highlight: item.status === 'Ожидание оплаты' }"
        style="background-color: #FBFBFB;">
        <md-table-cell md-label="Подписка" md-sort-by="subscription.name">{{ item.subscription.name }}</md-table-cell>
        <md-table-cell md-label="Описание" md-sort-by="subscription.description">{{ item.subscription.description }}
        </md-table-cell>
        <md-table-cell md-label="Цена" md-sort-by="amount">{{ item.amount }} руб.</md-table-cell>
        <md-table-cell md-label="Статус" md-sort-by="executed">{{ item.executed ? 'Успешно' : 'Ошибка' }}</md-table-cell>
        <md-table-cell md-label="Дата" md-sort-by="created">{{ formatDate(item.created) }}</md-table-cell>
      </md-table-row>
    </md-table>
    <!-- <subscription-cancellation /> -->
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PaymentHistory',
  data() {
    return {
      payments: [],
    };
  },
  computed: {
    isPaymentPending() {
      return this.payments.some(payment => payment.status === 'Ожидание оплаты');
    },
  },
  methods: {
    loadData() {
      axios.get('/api/subscription/payment/')
        .then(response => {
          this.payments = response.data.sort((a, b) => {
            return a.status !== b.status ? a.status === 'Ожидание оплаты' ? -1 : 1 : new Date(b.date_create) -
              new Date(a.date_create);
          });
        });
    },
    continuePayment() {
      const pendingPayment = this.payments.find(payment => payment.status === 'Ожидание оплаты');
      if (pendingPayment) {
        window.location.href = pendingPayment.payment_url;
      }
    },
    formatDate(date) {
      const parsedDate = Date.parse(date);
      return isNaN(parsedDate) ? '' : new Date(parsedDate).toLocaleDateString('ru-RU', { year: 'numeric', month: '2-digit', day: '2-digit' });
    },
  },
  mounted() {
    this.loadData();
  },
}
</script>

<style>

.button-continue {
  border-radius: 5px;
  border: 1px solid #002586;
  text-transform: none;
}

.highlight {
  background-color: #f2f2f2;
}
</style>