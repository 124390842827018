<template>
    <div>
      <md-button @click="goBack">Назад</md-button>
      <h3>Введите код</h3>
      <p>На ваш номер телефона поступит звонок, необходимо ввести последние 4 цифры.</p>
      <md-field>
        <label>Код</label>
        <md-input v-model="code"></md-input>
      </md-field>
      <div>Таймер: {{ timeLeft }}</div>
      <md-button @click="submitCode">Отправить</md-button>
    </div>
  </template>
  
  <script>
   import {
        processErrorResponse
    } from '@/axios'
  export default {
    name: "PhoneVerification",
    data() {
      return {
        code: "",
        timeLeft: 90,
        timer: null,
      };
    },
    mounted() {
      this.startTimer();
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
    methods: {
      goBack() {
        this.$emit("go-back");
      },
      startTimer() {
        this.timer = setInterval(() => {
          if (this.timeLeft > 0) {
            this.timeLeft -= 1;
          } else {
            clearInterval(this.timer);
            // Обработка истечения времени
          }
        }, 1000);
      },
      submitCode() {
        this.$refs.form.validate(valid => {
                    if (valid) {
                        this.code = ""
                        this.$axios.post('/auth/reg/', {
                                'code': this.model.code,
                            })
                            .then(() => {
                                this.model = {}
                            })
                            .catch(({
                                response
                            }) => {
                                if (response.status == 400) {
                                    processErrorResponse(this, response)
                                } else {
                                    this.$message({
                                        message: 'Внутренняя ошибка сервера',
                                        type: 'error'
                                    })
                                }
                                this.showCaptcha = true
                            })
                            .then(() => {
                                this.loading = false
                            })
                    }
                })},
    },
  };
  </script>
  