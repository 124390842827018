<template>
    <el-autocomplete class="inline-input" v-model="input" :fetch-suggestions="querySearch" placeholder="Введите отдел"
        @select="handleSelect" @input="handleInput">
        <template #append>
            <el-button icon="el-icon-close" @click="resetInput"></el-button>
        </template>
    </el-autocomplete>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'DepartmentRangePicker',
        data() {
            return {
                departments: [],
                input: '',
            };
        },
        async created() {
            try {
                const response = await axios.get('/api/organization/structure/as_list/');
                this.departments = response.data.map(item => ({
                    value: item.name,
                    key: item.id
                }));
            } catch (err) {
                console.error(err);
            }
        },
        methods: {
            querySearch(queryString, cb) {
                const results = this.departments.filter((department) => {
                    return department.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
                });
                cb(results);
            },
            handleSelect(item) {
                this.$emit('select', item.key);
            },
            handleInput(value) {
                if (!value) {
                    this.$emit('select', null);
                }
            },
            resetInput() {
                this.input = '';
                this.$emit('select', null);
            },
        },
    };
</script>