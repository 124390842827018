<template>
    <div>
      <Reg v-if="currentStep === 'reg'" @go-to-captcha="goToCaptcha" />
      <Captcha v-if="currentStep === 'captcha'" @go-back="goToReg" @submit-captcha="goToPhoneCheck" />
      <PhoneCheck v-if="currentStep === 'phoneCheck'" @go-back="goToCaptcha" />
    </div>
  </template>
  
  <script>
  import Reg from './Reg.vue';
  import Captcha from './Captcha.vue';
  import PhoneCheck from './PhoneVerification.vue';
  
  export default {
    name: 'RegistrationFlow',
    components: {
      Reg,
      Captcha,
      PhoneCheck,
    },
    data() {
      return {
        currentStep: 'reg',
      };
    },
    methods: {
      goToReg() {
        this.currentStep = 'reg';
      },
      goToCaptcha() {
        this.currentStep = 'captcha';
      },
      goToPhoneCheck() {
        this.currentStep = 'phoneCheck';
      },
    },
  };
  </script>
  