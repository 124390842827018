<template>
    <div class="right-side">
        <Layout>
        <div style="margin-bottom: 10px;">
            <span style="font-family: 'Gilroy-Light'; font-size: 45px; color: #002586; font-weight:1000;margin-bottom: 15px;">DRAGONSOFT</span>
        </div>  
        <md-tabs  v-model="activeTab">
            <md-tab class="reg-change" id="signIn" md-label="Войти" name="signIn" exact >
                <LoginForm />
            </md-tab>
            <md-tab class="reg-change" id="signUp" md-label="Регистрация">
                <RegistrationFlow />
            </md-tab>
        </md-tabs>
    </Layout>
    </div>
</template>

<script>

import LoginForm from './Login.vue'
import RegistrationFlow from './RegistrationFlow.vue'
import Layout from './Layout.vue'

export default {
    name: 'AuthForm',
    components: {
        LoginForm,
        RegistrationFlow,
        Layout
    },
    data() {
        let activeTab = "signIn"
        if (this.$route.query.signUp) {
            activeTab = "signUp"
        }
        return {
            activeTab: activeTab
        }
    },
    methods: {}
}
</script>

<style scoped>

.logo{
font-weight: 600;
font-size: 50px;
margin-bottom: 20px;
}

.right-side{
    width: 100vw;
    align-items: end;
}
</style>
