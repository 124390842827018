<template>
  <Settingtab/>
</template>

<script>
import Settingtab from '@/components/settings/Settinguser.vue';

export default {
  name: 'SettingsPage',
  components: {
    Settingtab,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
