<template>
  <div id="app" :class="isLoggedIn? '': 'auth'">
    <el-container v-if="!isLoggedIn">
      <router-view v-if="!isLoggedIn"></router-view>
    </el-container>

    <div v-if="isLoggedIn">
      <el-container class="main-container" v-if="$route.name != 'tariffs'">
        <Aside />
        <el-main  id="main">
          <router-view></router-view>
        </el-main>
      </el-container>

      <div v-if="$route.name == 'tariffs'">
        <router-view></router-view>
      </div>

      <el-backtop target="#app"></el-backtop>
    </div>
  </div>
</template>

<script>
  import {
    mapGetters
  } from 'vuex'

  import Aside from '@/Layout/Aside.vue'

  export default {
    components: {
      Aside
    },
    data() {
      return {
        loading: false,
        activeIndex: "1"
      }
    },
    computed: {
      ...mapGetters('me', {
        isLoggedIn: 'isLoggedIn'
      })
    },
    name: 'App'
  }
</script>

<style lang="scss">
 #main {
    overflow: visible;
    overflow-x: hidden;
  }
</style>