import { render, staticRenderFns } from "./InProgressProcessList.vue?vue&type=template&id=8479b2a0&scoped=true&"
import script from "./InProgressProcessList.vue?vue&type=script&lang=js&"
export * from "./InProgressProcessList.vue?vue&type=script&lang=js&"
import style0 from "./InProgressProcessList.vue?vue&type=style&index=0&id=8479b2a0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8479b2a0",
  null
  
)

export default component.exports