<template>
  <div id="chart"  >
    <canvas id="chartCanvas"></canvas>
  </div>
</template>

<script>
import axios from 'axios';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
  name: 'ChartComponent',
  props: ['userId'],
  data() {
    return {
      chart: null,
      color: '#CAF0F8'
    };
  },
  watch: {
    async userId(newUserId) {
      await this.fetchData(newUserId);
      this.drawChart();
    }
  },
  mounted() {
    this.fetchData(this.userId);
  },
  methods: {
    async fetchData(userId) {
      console.log("fetchData called with userId:", userId);
      let url = '/api/graph/graph/statistics/?timespan=month';
      if (userId) {
        url += `&user_id=${userId}`;
      }

      try {
        const response = await axios.get(url);
        const data = Object.entries(response.data).map(([month, data]) => ({
          x: month,
          y: data.closed_percent
        }));
        this.data = data;
        this.drawChart(); // Redraw chart with new data
      } catch (error) {
        console.error(error);
        // Additional error handling can be added here, e.g., displaying a message to the user
      }
    },
    drawChart() {
      if (this.chart) {
        this.chart.destroy();
      }

      const ctx = document.getElementById('chartCanvas').getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          datasets: [{
            label: 'Процент закрытых задач',
            data: this.data,
            fill: true,
            backgroundColor: this.color,
            borderColor: this.color,
            tension: 0.1 // Smooths the line
          }]
        },
        options: {
          scales: {
            x: {
              type: 'category',
              labels: this.data.map(d => d.x)
            },
            y: {
              beginAtZero: true,
              max: 100
            }
          },
          plugins: {
            legend: {
              display: false // Hide legend if not required
            }
          }
        }
      });
    }
  }
};
</script>

<style scoped>

</style>
